import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RealPropertyRow from './RealProperty/RealProperty';
import MineralInterestRow from './MineralInterest/MineralInterest';
import EquitableClaimSeparateRow from './EquitableClaimSeparate/EquitableClaimSeparate'
import EquitableClaimMaritalRow from './EquitableClaimMarital/EquitableClaimMarital';
import AssetHeldBenefitRow from './AssetHeldBenefit/AssetHeldBenefit';
import AssetHeldAnotherRow from './AssetHeldAnother/AssetHeldAnother';
import ChildrenRow from './Children/Children';
import OtherLiabilitiesRow from './OtherLiabilities/OtherLiabilities';
import ContingentLiabilitiesRow from './ContingentLiabilities/ContingentLiabilities';
import AmountsOwedRow from './AmountsOwed/AmountsOwed';
import TaxRow from './TaxLiabilities/Tax';
import CreditCardRow from './CreditCard/CreditCard';
import OtherAssetsRow from './OtherAssets/OtherAssets';
import ContingentRow from './Contingent/Contingent';
import StorageFacilitiesRow from './StorageFacilities/StorageFacilities';
import SafeDepositRow from './SafeDeposit/SafeDeposit';
import IntellectualPropertyRow from './IntellectualProperty/IntellectualProperty';
import TravelAwardRow from './TravelAward/TravelAward';
import ClubMembershipRow from './ClubMemberships/ClubMemberships';
import LiveStockRow from './LiveStock/LiveStock';
import PetsRow from './Pets/Pets';
import BothPossessionRow from './BothPossession/BothPossession';
import SpousePossessionRow from './SpousePossession/SpousePossession';
import ClientPossessionRow from './ClientPossession/ClientPossession';
import MoneyOwedRow from './MoneyOwed/MoneyOwed';
import VehicleRow from './Vehicle/Vehicle';
import AnnuitiesRow from './Annuities/Annuities';
import LifeInsuranceRow from './LifeInsurance/LifeInsurance';
import UnionRow from './Union/Union';
import CompensationRow from './Compensation/Compensation';
import GovernmentRow from './Government/Government';
import NonQualifiedRow from './NonQualified/NonQualified';
import MilitaryRow from './Military/Military';
import IRASEPRow from './IRASEP/IRASEP';
import BenefitRow from './Benefit/Benefit';
import ContributionRow from './Contribution/Contribution';
import BusinessInterestRow from './BusinessInterest/BusinessInterest';
import BonusesRow from './Bonuses/Bonuses';
import EmployeeStockRow from './EmployeeStock/EmployeeStock';
import StockOptionRow from './StockOption/StockOption';
import PublicStockRow from './PublicStock/PublickStock';
import BrokerageRow from './Brokerage/Brokerage';
import BankAccountsRow from './BankAccounts/BankAccounts';
import EquivalentsRow from './Equivalents/Equivalents';
import CashRow from './Cash/Cash';
import PreciousMetalsRow from './PreciousMetals/PreciousMetals';
import { EstateContext } from '../../../../../hooks/ContextStore';
import { EncryptStorage } from 'encrypt-storage';

function EstateSpreadsheet(props){
    const [open, setOpen]                  = useState(false);
    const mobile                           = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]          = useState(mobile.matches);
    const [category, setCategory]          = useContext(EstateContext);
    const data                             = category != undefined ? category : null;
    const encryptStorage                   = new EncryptStorage("secret-key");
    
    const initialVal = () => {
        return {
          client_case_id: data ? data.client_case_id : false,
          real_property: data ? Boolean(data.real_property) : false,
          mineral_interest: data ? Boolean(data.mineral_interest) : false,
          cash: data ? Boolean(data.cash) : false,
          equivalents: data ? Boolean(data.equivalents) : false,
          bank_account: data ? Boolean(data.bank_account) : false,
          brokerage: data ? Boolean(data.brokerage) : false,
          public_stocks: data ? Boolean(data.public_stocks) : false,
          employee_stock_option: data ? Boolean(data.employee_stock_option) : false,
          employee_stock: data ? Boolean(data.employee_stock) : false,
          bonuses: data ? Boolean(data.bonuses) : false,
          business_interest: data ? Boolean(data.business_interest) : false,
          contribution_plan: data ? Boolean(data.contribution_plan) : false,
          benefit_plan: data ? Boolean(data.benefit_plan) : false,
          ira_sep: data ? Boolean(data.ira_sep) : false,
          military_benefit: data ? Boolean(data.military_benefit) : false,
          non_qualified_plan: data ? Boolean(data.non_qualified_plan) : false,
          government_benefits: data ? Boolean(data.government_benefits) : false,
          compensation_benefits: data ? Boolean(data.compensation_benefits) : false,
          union_benefits: data ? Boolean(data.union_benefits) : false,
          life_insurance: data ? Boolean(data.life_insurance) : false,
          annuities: data ? Boolean(data.annuities) : false,
          vehicles: data ? Boolean(data.vehicles) : false,
          money_owned: data ? Boolean(data.money_owned) : false,
          client_possession: data ? Boolean(data.client_possession) : false,
          spouse_possession: data ? Boolean(data.spouse_possession) : false,
          spouse_both: data ? Boolean(data.spouse_both) : false,
          pets: data ? Boolean(data.pets) : false,
          livestock: data ? Boolean(data.livestock) : false,
          club_membership: data ? Boolean(data.club_membership) : false,
          travel_award_benefit: data ? Boolean(data.travel_award_benefit) : false,
          intellectual_property: data ? Boolean(data.intellectual_property) : false,
          precious_metals: data ? Boolean(data.precious_metals) : false,
          safe_deposit: data ? Boolean(data.safe_deposit) : false,
          contingent_assets: data ? Boolean(data.contingent_assets) : false,
          other_assets: data ? Boolean(data.other_assets) : false,
          storage_facilities: data ? Boolean(data.storage_facilities) : false,
          credit_card: data ? Boolean(data.credit_card) : false,
          federal_liabilities: data ? Boolean(data.federal_liabilities) : false,
          contingent_liabilities: data
            ? Boolean(data.contingent_liabilities)
            : false,
          other_liabilities: data ? Boolean(data.other_liabilities) : false,
          children_assets: data ? Boolean(data.children_assets) : false,
          asset_held_for_benefit: data
            ? Boolean(data.asset_held_for_benefit)
            : false,
          asset_held_for_either_party: data
            ? Boolean(data.asset_held_for_either_party)
            : false,
          equitable_for_community_estate: data
            ? Boolean(data.equitable_for_community_estate)
            : false,
          equitable_for_separate_estate: data
            ? Boolean(data.equitable_for_separate_estate)
            : false,
          amount_owned_to_attorney_or_professionals: data
            ? Boolean(data.amount_owned_to_attorney_or_professionals)
            : false,
        };
    };

    console.log('INITIAL VALUES: ', initialVal());

    const {
        updateRealProperty,
        updateMineralInterest,
        updateEstateIntellectualProperty,
        updateEstateOtherAsset,
        updateCash,
        updateFinancialInstitution,
        updateBrokerage,
        updatePublicStock,
        updateStockOption,
        updateEmployeeStock,
        updateBonus,
        updateCloseslyHeldBusiness,
        updateContribution,
        updateBenefitPlan,
        updateIRASEP,
        updateMilitaryBenefits,
        updateNonQualifiedPlan,
        updateGovernmentBenefit,
        updateOtherDeferedCompensation,
        updateUnionBenefit,
        updateLifeInsurance,
        updateAnnuity,
        updateVehicle,
        updateMoneyOwed,
        updateAsset,
        updateClubMembership,
        updateTravelAward,
        updateSafeDepositBox,
        updateStorageFacility,
        updateContingentAsset,
        updateCreditCard,
        updateTaxLiability,
        updateAmountOwed,
        updateContingentLiability,
        updateOtherLiability,
        updateChildAsset,
        updateEquitableClaim,
        updatePreciousMetals,
        updateEstatePropertiesInTrust
    }                                      = props;

    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };

        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }

    }, []);

    const onEstateCategoriesUpdated = () => {
        setCategory(encryptStorage.getItem('EstateCategories'));
      };
      
    useEffect(() => {
        window.addEventListener("storage", onEstateCategoriesUpdated)
        return () => {
            window.removeEventListener("storage", onEstateCategoriesUpdated);
        };
    },[]);
      
    return(
        <>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell className="text-uppercase text-muted">DESCRIPTION</TableCell>
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">Value</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">DEBT</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">CLIENT SP</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">SPOUSE SP</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">NET VALUE</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO CLIENT</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO SPOUSE</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">%</TableCell> : null }
                <TableCell align="left" className="text-uppercase text-muted">ACTION</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* real property */}
                    {initialVal().real_property?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>REAL PROPERTY</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().real_property? props.division && props.division.real_property.map((row) => (
                        <RealPropertyRow row={row} updateRealProperty={updateRealProperty}/>
                    )):''}
                {/* real property */}

                {/* mineral interest */}
                    {initialVal().mineral_interest?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Mineral Interest</TableCell>
                    </TableRow>
                    :''}
                    {initialVal().mineral_interest?props.division && props.division.mineral_interest.map((row) => (
                        <MineralInterestRow row={row} updateMineralInterest={updateMineralInterest} />
                    )):''}
                {/* mineral interest */}

                {/* cash */}
                    {initialVal().cash?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Cash</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().cash?
                    props.division && props.division.cash.map((row) => (
                        <CashRow row={row} updateCash={updateCash}/>
                    ))
                    :''}
                {/* cash */}


                {/* equivalents */}
                    {initialVal().equivalents?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Equivalents</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().equivalents? props.division && props.division.cash_equivalent.map((row) => (
                        <EquivalentsRow row={row} updateCash={updateCash}/>
                    )):''}
                {/* equivalents */}


                {/* Bank Accounts */}
                {initialVal().bank_account ?
                    <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Bank Accounts</TableCell>
                    </TableRow>:''}

                    {initialVal().bank_account ? props.division && props.division.financial_institution.map((row) => (
                        <BankAccountsRow row={row} updateFinancialInstitution={updateFinancialInstitution} />
                    )):''}
                {/* Bank Accounts */}

                {/* Brokerage */}
                {initialVal().brokerage ?
                    <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Brokerage</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().brokerage ? props.division && props.division.brokerage.map((row) => (
                        <BrokerageRow row={row} updateBrokerage={updateBrokerage} />
                    )):''}
                {/* Brokerage */}

                {/* Publicly traded, stocks, bonds, and other securities */}
                    {initialVal().public_stocks ?
                        <TableRow>
                            <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Publicly traded, stocks, bonds, and other securities</TableCell>
                        </TableRow>:''}

                    {initialVal().public_stocks ? props.division && props.division.public_stock.map((row) => (
                        <PublicStockRow row={row} updatePublicStock={updatePublicStock}/>
                    )):''}
                {/* Publicly traded, stocks, bonds, and other securities */}


                {/* Employee Stock Options */}
                    {initialVal().employee_stock_option ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Employee Stock Options</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().employee_stock_option ? props.division && props.division.stocks_option.map((row) => (
                        <StockOptionRow row={row} updateStockOption={updateStockOption}/>
                    )):''}
                {/* Employee Stock Options */}


                {/* Employee Options */}
                    {initialVal().employee_stock ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Employee Stock</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().employee_stock ? props.division && props.division.employee_stock.map((row) => (
                        <EmployeeStockRow row={row} updateEmployeeStock={updateEmployeeStock}/>
                    )): ''}
                {/* Employee Options */}

                {/* Bonuses */}
                    {initialVal().bonuses ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Bonuses</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().bonuses ? props.division && props.division.bonus.map((row) => (
                        <BonusesRow row={row} updateBonus={updateBonus} />
                    )): ''}
                {/* Bonuses */}

                {/* Closely Held Business Interests */}
                    {initialVal().business_interest ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Closely Held Business Interests</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().business_interest ? props.division && props.division.closely_held_business.map((row) => (
                        <BusinessInterestRow row={row} updateCloseslyHeldBusiness={updateCloseslyHeldBusiness}/>
                    )):''}
                {/* Closely Held Business Interests */}

                {/* Defined Contribution Plans */}
                    {initialVal().contribution_plan ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Defined Contribution Plans</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().contribution_plan ? props.division && props.division.defined_contribution_plan.map((row) => (
                        <ContributionRow row={row} updateContribution={updateContribution}/>
                    )): ''}
                {/* Defined Contribution Plans */}

                {/* Defined Benefit Plans */}
                    {initialVal().benefit_plan ?
                        <TableRow>
                            <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Defined Benefit Plans</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().benefit_plan ? props.division && props.division.defined_benefit_plan.map((row) => (
                        <BenefitRow row={row} updateBenefitPlan={updateBenefitPlan}/>
                    )):''}
                {/* Defined Benefit Plans */}

                {/* IRA/SEP */}
                    {initialVal().ira_sep ?
                    <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>IRA/SEP</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().ira_sep ? props.division && props.division.ira_sep.map((row) => (
                        <IRASEPRow row={row} updateIRASEP={updateIRASEP}/>
                    )):''}
                {/* IRA/SEP */}

                {/* Military Benefits */}
                    {initialVal().military_benefit ?
                        <TableRow>
                            <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Military Benefits</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().military_benefit ? props.division && props.division.military_benefit.map((row) => (
                        <MilitaryRow row={row} updateMilitaryBenefits={updateMilitaryBenefits} />
                    )): ''}
                {/* Military Benefits */}

                {/* Non-qualified Plans */}
                    {initialVal().non_qualified_plan ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Non-qualified Plans</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().non_qualified_plan ?props.division && props.division.non_qualified_plan.map((row) => (
                        <NonQualifiedRow row={row} updateNonQualifiedPlan={updateNonQualifiedPlan} />
                    )): ''}
                {/* Non-qualified Plans */}

                 {/* Government Benefits */}
                    {initialVal().government_benefits ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Government Benefits</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().government_benefits ?  props.division && props.division.government_benefit.map((row) => (
                        <GovernmentRow row={row} updateGovernmentBenefit={updateGovernmentBenefit}/>
                    )):''}
                {/* Government Benefits */}

                {/* Other Deferred Compensation Benefits */}
                    {initialVal().compensation_benefits ?
                    <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Other Deferred Compensation Benefits</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().compensation_benefits ? props.division && props.division.other_deferred_benefit.map((row) => (
                        <CompensationRow row={row} updateOtherDeferedCompensation={updateOtherDeferedCompensation}/>
                    )):''}
                {/* Other Deferred Compensation Benefits */}

                {/* Union Benefits */}
                    {initialVal().union_benefits?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Union Benefits</TableCell>
                    </TableRow>
                    : ''}

                    {initialVal().union_benefits? props.division && props.division.union_benefits.map((row) => (
                        <UnionRow row={row} updateUnionBenefit={updateUnionBenefit} />
                    )): ''}
                {/* Union Benefits */}

                {/* Life Insurance */}
                    {initialVal().life_insurance?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Life Insurance</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().life_insurance ? props.division && props.division.life_insurance.map((row) => (
                        <LifeInsuranceRow row={row} updateLifeInsurance={updateLifeInsurance}/>
                    )): ''}
                {/* Life Insurance */}

                 {/* Annuities */}
                    {initialVal().annuities?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Annuities</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().annuities ? props.division && props.division.annuity.map((row) => (
                        <AnnuitiesRow row={row} updateAnnuity={updateAnnuity}/>
                    )):''}
                {/* Annuities */}

                {/* Motor Vehicles, Airplanes, Cycles, etc. */}
                    {initialVal().vehicles ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Motor Vehicles, Airplanes, Cycles, etc.</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().vehicles ? props.division && props.division.vehicles.map((row) => (
                        <VehicleRow row={row} updateVehicle={updateVehicle}/>
                    )): ''}
                 {/* Motor Vehicles, Airplanes, Cycles, etc. */}

                 {/* Money Owed to Me or My Spouse */}
                    { initialVal().money_owned ?
                    <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Money Owed to Me or My Spouse</TableCell>
                    </TableRow>
                    : ''}

                    {initialVal().money_owned ? props.division && props.division.money_owed.map((row) => (
                        <MoneyOwedRow row={row} updateMoneyOwed={updateMoneyOwed}/>
                    )): ''}
                 {/* Money Owed to Me or My Spouse */}

                {/* In Possession of Client */}
                    {initialVal().client_possession ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Personal Property in Possession of Client</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().client_possession ? props.division && props.division.client_possession.map((row) => (
                        <ClientPossessionRow key={row.id} row={row} updateAsset={updateAsset}/>
                    )): ''}
                {/* In Possession of Client */}

                {/* In Possession of Spouse */}
                    {initialVal().spouse_possession ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Personal Property in Possession of Spouse</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().spouse_possession ? props.division && props.division.spouse_possession.map((row) => (
                        <SpousePossessionRow key={row.id} row={row} updateAsset={updateAsset} />
                    )): ''}
                {/* In Possession of Spouse */}

                 {/* In Possession of Both */}
                    {initialVal().spouse_both ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Personal Property in Possession of Both</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().spouse_both ? props.division && props.division.both_possession.map((row) => (
                        <BothPossessionRow key={row.id} row={row} updateAsset={updateAsset}/>
                    )): ''}
                 {/* In Possession of Both */}

                 {/* Pets */}
                    {initialVal().pets ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Pets</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().pets ? props.division && props.division.pets.map((row) => (
                        <PetsRow key={row.desc} row={row} updateAsset={updateAsset}/>
                    )): ''}
                {/* Pets */}

                 {/* Livestock */}
                    {initialVal().livestock ?
                        <TableRow>
                            <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Livestock</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().livestock ? props.division && props.division.live_stock.map((row) => (
                        <LiveStockRow key={row.desc} row={row} updateAsset={updateAsset}/>
                    )): ''}
                {/* Livestock */}

                {/* Club Memberships */}
                    {initialVal().club_membership ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Club Memberships</TableCell>
                    </TableRow>
                    : ''}

                    {initialVal().club_membership ? props.division && props.division.club_membership.map((row) => (
                        <ClubMembershipRow key={row.id} row={row} updateClubMembership={updateClubMembership}/>
                    )): ''}
                 {/* Club Memberships */}

                {/* Travel Award Benefits */}
                    {initialVal().travel_award_benefit ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Travel Award Benefits</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().travel_award_benefit ?  props.division && props.division.travel_award_benefit.map((row) => (
                        <TravelAwardRow key={row.id} row={row} updateTravelAward={updateTravelAward} />
                    )): ''}
                {/* Travel Award Benefits */}

                {/* Intellectual Property */}
                    {initialVal().intellectual_property ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Intellectual Property</TableCell>
                    </TableRow>
                    : ' '}

                    {initialVal().intellectual_property ? props.division && props.division.intellectual_property.map((row) => (
                        <IntellectualPropertyRow key={row.desc} row={row} updateEstateIntellectualProperty={updateEstateIntellectualProperty}/>
                    )): ''}
                {/* Intellectual Property */}

                {/* Precious Metals */}
                    {initialVal().precious_metals ?
                        <TableRow>
                            <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Precious Metals</TableCell>
                        </TableRow>
                    : ' '}

                    {initialVal().precious_metals ? props.division && props.division.precious_metals.map((row) => (
                        <PreciousMetalsRow key={row.desc} row={row} updatePreciousMetals={updatePreciousMetals}/>
                    )): ''}
                {/* Precious Metals */}


                {/* Safe Deposit Boxes */}
                    {initialVal().safe_deposit ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Safe Deposit Boxes</TableCell>
                        </TableRow>
                    :''}

                    {initialVal().safe_deposit ?  props.division && props.division.safe_deposit_box.map((row) => (
                        <SafeDepositRow key={row.id} row={row} updateSafeDepositBox={updateSafeDepositBox}/>
                    )): ''}
                {/* Safe Deposit Boxes */}


                {/* Storage Facilities*/}
                    {initialVal().storage_facilities ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Storage Facilities</TableCell>
                        </TableRow>
                    :'' }

                    {initialVal().storage_facilities ?  props.division && props.division.storage_facility.map((row) => (
                        <StorageFacilitiesRow key={row.desc} row={row} updateStorageFacility={updateStorageFacility} />
                    )): ''}
                {/* Storage Facilities*/}

                {/* Contingent Assets*/}
                    {initialVal().contingent_assets ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Contingent Assets</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().contingent_assets ? props.division && props.division.contingent_assets.map((row) => (
                        <ContingentRow key={row.desc} row={row} updateContingentAsset={updateContingentAsset}/>
                    )):''}
                {/* Contingent Assets*/}

                {/* Other Assets*/}
                    {initialVal().other_assets ?
                        <TableRow>
                        <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Other Assets</TableCell>
                        </TableRow>
                    : ''}

                    {initialVal().other_assets ? props.division && props.division.other_assets.map((row) => (
                        <OtherAssetsRow key={row.desc} row={row} updateEstateOtherAsset={updateEstateOtherAsset}/>
                    )): ''}
                {/* Other Assets*/}

                  {/*Children’s Assets */}
                  {initialVal().children_assets  ?
                  <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Children’s Assets</TableCell>
                </TableRow>: ''}

                { initialVal().children_assets  ?  props.division && props.division.child_account.map((row) => (
                    <ChildrenRow key={row.desc} row={row} updateChildAsset={updateChildAsset} />
                )):''}
                  {/*Children’s Assets */}

                {/*Assets Held by Either Party */}
                {initialVal().asset_held_for_benefit  ?
                  <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Assets Held by Either Party for the Benefit of Another</TableCell>
                </TableRow>: ''}
                
                {initialVal().asset_held_for_benefit ? props.division && props.division.asset_held_for_benefit.map((row)=>(
                    <AssetHeldBenefitRow key={row.desc} row={row} updateEstatePropertiesInTrust={updateEstatePropertiesInTrust}/>
                )):''}
                {/*Assets Held by Either Party */}

                {/*Assets Held by Another Party */}
                {initialVal().asset_held_for_either_party  ?
                  <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Assets Held by Another for Either Party</TableCell>
                </TableRow>: ''}
                
                {initialVal().asset_held_for_either_party ? props.division && props.division.asset_held_for_either_party.map((row)=>(
                    <AssetHeldBenefitRow key={row.desc} row={row} updateEstatePropertiesInTrust={updateEstatePropertiesInTrust}/>
                )):''}
                {/*Assets Held by Another Party */}


                {/* Equitable Claims in Favor of the Marital/Community Estate */}
                {initialVal().equitable_for_community_estate  ?
                <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Equitable Claims in Favor of the Marital/Community Estate</TableCell>
                </TableRow>:''}
                {initialVal().equitable_for_community_estate  ? props.division && props.division.equitable_claim_marital.map((row) => (
                    <EquitableClaimSeparateRow key={row.desc} row={row} updateEquitableClaim={updateEquitableClaim}/>
                )): ''}
                {/* Equitable Claims in Favor of the Marital/Community Estate */}

             {/*Equitable Claims in Favor of Either Party's Separate Estate*/}
             {initialVal().equitable_for_separate_estate ?
                <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Equitable Claims in Favor of Either Party's Separate Estate</TableCell>
                </TableRow>: ''}
                {initialVal().equitable_for_separate_estate  ? props.division && props.division.equitable_claim_either.map((row) => (
                    <EquitableClaimSeparateRow key={row.desc} row={row} updateEquitableClaim={updateEquitableClaim}/>
                )): ''}
                 {/*Equitable Claims in Favor of Either Party's Separate Estate*/}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default EstateSpreadsheet