import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from "@material-ui/icons";
import { 
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, 
    InputBase, 
    MenuItem, 
    Select, 
    TextField,
    withStyles,
    Theme
} from '@material-ui/core';
import Axios from 'axios';
import { EncryptStorage } from "encrypt-storage"; 
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from 'react';
import * as yup from "yup"; 
import { TimelineCategoriesContext } from '../../../../../hooks/ContextStore';


const validationSchema = yup.object({ 
    date: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("First name is required!"),
    title: yup.string() 
    .min(2, "Minimum 2 symbols") 
    .required("Last name is required!"),
});

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

function UpdateTimeline({updateTimeline, event}) {
    const [open, setOpen]                       = useState(false); 
    const encryptStorage                        = new EncryptStorage("secret-key");
    const decypt_data                           = encryptStorage.getItem("client_case_id");  
    const [source, setSource]                   = useState(); 
    const [checkbox,setCheckbox]                = useState(event.isApproximate);
    const [eventDate, setEventDate]             = useState('');
    const [eventTitle, setEventTitle]           = useState('');
    const [eventDesc, setEventDesc]             = useState(event.description);
    const [category, setCategory]               = useState(event?.event_category?.id);
    const [timelineCategories]                  = useContext(TimelineCategoriesContext);
    
    const onSubmit = async (values,props) =>{
        values["source"] = source;
        values["client_case_id"] = decypt_data;
        values["id"] = event.id;
        values['isApproximate'] = checkbox;
        values['event_category_id'] = category;
        updateTimeline(values);
        handleClose();
    }

    function handleClickOpen() {
        setSource(updateTimeline.source)
        setOpen(true); 
    }
    function onChange(e) {
        setCheckbox(checkbox?false:true);
      }


    function handleClose() {
        setOpen(false);
    }

    const formik = useFormik( 
    {
        enableReinitialize: true,
        initialValues: {
            title: eventTitle,
            date: eventDate,
            description: eventDesc,
        },
        validateOnBlur:true,
        onSubmit,
        validationSchema: validationSchema
    });
    
    useEffect(()=>{
        setEventDate(event.date);
        setEventTitle(event.title);
        setEventDesc(event.description);
    }, []);

    return (
        <>
            <IconButton aria-label="edit" size='small' onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Update Event</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">  
                        <div className="col-lg-12 mb-4">
                        <label>Title</label>
                            <TextField
                                placeholder="Short Title"
                                label="Short Title"
                                name="title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                                margin="normal"
                                variant="outlined"
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.title &&  formik.errors.title ? formik.errors.title:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                        <label>Date</label>
                            <TextField  
                                name="date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.date}
                                margin="normal"
                                variant="outlined"
                                type="date"
                            />
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.date &&  formik.errors.date ? formik.errors.date:""}
                            </span>
                        </div>
                        <div className="col-lg-12 mb-4">
                            <label>Description</label>
                            <TextField 
                                label="Description"
                                name="description"
                                multiline
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur} 
                                value={formik.values.description}
                                margin="normal"
                                variant="outlined"
                                rows={4}
                            /> 
                            <br/>
                            <span className='text-danger'>
                                {formik.touched.description &&  formik.errors.description ? formik.errors.description:""}
                            </span>
                        </div> 
                        <div className="col-lg-12 mb-4">
                            <label>Category</label>
                            {timelineCategories.length !== 0 ?
                            <Select
                                input={<BootstrapInput />}
                                name={"event_category"}
                                className={'w-100'} 
                                onChange={e=> setCategory(e.target.value)}
                                value={category}
                            > 
                                {timelineCategories.map((category, index) => (
                                    <MenuItem key={index} value={category.id}>{category.title}</MenuItem>
                                ))}
                            </Select>
                            :
                            ''
                            }
                        </div>
                        <div className="col-lg-12 mb-4">
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={checkbox}
                                onChange={onChange}
                                name="checked"
                                color="primary"
                            />
                            }
                            label="Date not exact/approximated"
                        />
                        </div>
                    </div>   
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained" size="large" color="secondary">
                            Cancel
                        </Button>
                        <Button className="text-white" variant="contained" color="primary" size="large" type="submit" disabled={!formik.isValid} autoFocus>
                            Submit
                        </Button>
                      </DialogActions>
                    </form>
                </DialogContentText>
                </DialogContent> 
            </Dialog> 
        </>
    )
}

export default UpdateTimeline
