import React from "react";

export default function VideoTutorials(){
    return(
        <div className=" py-4 col-md-6 col-sm-12 col-xxl-8">
          <div className={`card h-auto card-custom`}>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Video Tutorials
              </h3>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body text-center">
              <div className="d-flex justify-content-around">
                <div className="row mb-3">
                  <div className="p-2 col-md-4">
                    <iframe
                      width="100%"
                      height="150"
                      src="https://www.youtube.com/embed/W4u2yBVYtE8"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="p-2 col-md-4">
                    <iframe
                      width="100%"
                      height="150"
                      src="https://www.youtube.com/embed/qieLHj68m7A"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="p-2 col-md-4">
                    <iframe
                      width="100%"
                      height="150"
                      src="https://www.youtube.com/embed/m6VE1wr8m5I"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}