import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { EstateContext } from "../../../../../hooks/ContextStore";
import { Field, Form, Formik } from "formik";
import { Redirect } from "react-router-dom";

function EstateCategories(props) {
  const [category, setCategory]         = useContext(EstateContext);
  const data                            = category != undefined ? category : null;
  const [redirect, setRedirect]         = useState(false);
  const encryptStorage                  = new EncryptStorage('secret-key');
  const open                            = props.open;
  const setOpen                         = props.setOpen;
  const handleClose                     = props.handleClose;

  const initialVal = () => {
    return {
      client_case_id: data ? data.client_case_id : false,
      real_property: data ? Boolean(data.real_property) : false,
      mineral_interest: data ? Boolean(data.mineral_interest) : false,
      cash: data ? Boolean(data.cash) : false,
      equivalents: data ? Boolean(data.equivalents) : false,
      bank_account: data ? Boolean(data.bank_account) : false,
      brokerage: data ? Boolean(data.brokerage) : false,
      public_stocks: data ? Boolean(data.public_stocks) : false,
      employee_stock_option: data ? Boolean(data.employee_stock_option) : false,
      employee_stock: data ? Boolean(data.employee_stock) : false,
      bonuses: data ? Boolean(data.bonuses) : false,
      business_interest: data ? Boolean(data.business_interest) : false,
      contribution_plan: data ? Boolean(data.contribution_plan) : false,
      benefit_plan: data ? Boolean(data.benefit_plan) : false,
      ira_sep: data ? Boolean(data.ira_sep) : false,
      military_benefit: data ? Boolean(data.military_benefit) : false,
      non_qualified_plan: data ? Boolean(data.non_qualified_plan) : false,
      government_benefits: data ? Boolean(data.government_benefits) : false,
      compensation_benefits: data ? Boolean(data.compensation_benefits) : false,
      union_benefits: data ? Boolean(data.union_benefits) : false,
      life_insurance: data ? Boolean(data.life_insurance) : false,
      annuities: data ? Boolean(data.annuities) : false,
      vehicles: data ? Boolean(data.vehicles) : false,
      money_owned: data ? Boolean(data.money_owned) : false,
      client_possession: data ? Boolean(data.client_possession) : false,
      spouse_possession: data ? Boolean(data.spouse_possession) : false,
      spouse_both: data ? Boolean(data.spouse_both) : false,
      pets: data ? Boolean(data.pets) : false,
      livestock: data ? Boolean(data.livestock) : false,
      club_membership: data ? Boolean(data.club_membership) : false,
      travel_award_benefit: data ? Boolean(data.travel_award_benefit) : false,
      intellectual_property: data ? Boolean(data.intellectual_property) : false,
      safe_deposit: data ? Boolean(data.safe_deposit) : false,
      contingent_assets: data ? Boolean(data.contingent_assets) : false,
      precious_metals: data ? Boolean(data.precious_metals) : false,
      other_assets: data ? Boolean(data.other_assets) : false,
      storage_facilities: data ? Boolean(data.storage_facilities) : false,
      credit_card: data ? Boolean(data.credit_card) : false,
      federal_liabilities: data ? Boolean(data.federal_liabilities) : false,
      contingent_liabilities: data
        ? Boolean(data.contingent_liabilities)
        : false,
      other_liabilities: data ? Boolean(data.other_liabilities) : false,
      children_assets: data ? Boolean(data.children_assets) : false,
      asset_held_for_benefit: data
        ? Boolean(data.asset_held_for_benefit)
        : false,
      asset_held_for_either_party: data
        ? Boolean(data.asset_held_for_either_party)
        : false,
      equitable_for_community_estate: data
        ? Boolean(data.equitable_for_community_estate)
        : false,
      equitable_for_separate_estate: data
        ? Boolean(data.equitable_for_separate_estate)
        : false,
      amount_owned_to_attorney_or_professionals: data
        ? Boolean(data.amount_owned_to_attorney_or_professionals)
        : false,
    };
  };

  if(redirect){
    return <Redirect to="/estates/default" />;
  }
  
  // if(success){
  //   window.location.reload();
  //   return <Redirect to="/estate" />;
  // }
  
  return (
    <>

      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <div className="d-flex justify-content-center">
              <h1 className="text-dark">Categories</h1>
            </div>
            <div
              className="login-form login-signin p-5"
              id="kt_login_signin_form"
            >
              <Formik
                initialValues={initialVal()}
                onSubmit={async (values) => { 
                  const response = await Axios.post(
                    "api/estate/category/default/store",
                    values
                  ).catch((err) => {
                    if (err && err.response) console.log("error", err);
                  });
              
                  if (response) {
                    setCategory(response.data.data[0]);
                    encryptStorage.setItem('EstateCategories', response.data.data[0]);
                    if(!response.data.data.length ){
                      setRedirect(true);
                      setOpen(false)
                    }else{
                      setOpen(false)
                    }
                  } 
                }}
              >
                {({ values }) => (
                  <Form>
                    <div className="form fv-plugins-bootstrap fv-plugins-framework">
                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Real Property
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="real_property"
                              />
                              Real Property
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="mineral_interest"
                              />
                              Mineral Interest
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Cash & Equivalents
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="cash"
                              />
                              Cash
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="equivalents"
                              />
                              Equivalents (Cashier’s Checks, Money Order, etc.)
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Bank Accounts
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="bank_account"
                              />
                              Bank Accounts
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Brokerage
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="brokerage"
                              />
                              Brokerage
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Stocks
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="public_stocks"
                              />
                              Publicly traded, stocks, bonds, and other securities not held in a brokerage account
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="employee_stock_option"
                              />
                              Employee Stock Options
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="employee_stock"
                              />
                              Employee stock
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Bonuses
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="bonuses"
                              />
                              Bonuses
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Closely Held Business Interests
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="business_interest"
                              />
                              Closely Held Business Interests
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Retirement Benefits
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="contribution_plan"
                              />
                              Defined Contribution Plans
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="benefit_plan"
                              />
                              Defined Benefit Plans
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="ira_sep"
                              />
                              IRA/SEP
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="military_benefit"
                              />
                              Military Benefits
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="non_qualified_plan"
                              />
                              Non-qualified Plans
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="government_benefits"
                              />
                              Government Benefits
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="compensation_benefits"
                              />
                              Other Deferred Compensation Benefits
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="union_benefits"
                              />
                              Union Benefits
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Life Insurance and Annuities
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="life_insurance"
                              />
                              Life Insurance
                            </label>
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="annuities"
                              />
                              Annuities
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Motor Vehicles, Airplanes, Cycles, etc.
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="vehicles"
                              />
                              Motor Vehicles, Airplanes, Cycles, etc.
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Money Owed to Me or My Spouse
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="money_owned"
                              />
                              Money Owed to Me or My Spouse
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Personal Property
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="client_possession"
                              />
                              In Possession of Client
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="spouse_possession"
                              />
                              In Possession of Spouse
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="spouse_both"
                              />
                              In Possession of Both
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Pets & Livestock
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="pets"
                              />
                              Pets
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="livestock"
                              />
                              Livestock
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Club Memberships
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="club_membership"
                              />
                              Club Memberships
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Travel Award Benefits
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="travel_award_benefit"
                              />
                              Travel Award Benefits
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Other Types of Assets
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="intellectual_property"
                              />
                              Intellectual Property
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="precious_metals"
                              />
                              Precious Metals
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="safe_deposit"
                              />
                              Safe Deposit Boxes
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="storage_facilities"
                              />
                              Storage Facilities
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="contingent_assets"
                              />
                              Contingent Assets
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="other_assets"
                              />
                              Other Assets
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                          Debts & Other Liabilities
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="credit_card"
                              />
                              Credit Cards & Revolving Credit
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="federal_liabilities"
                              />
                              Federal, State, and Local Tax Liabilities
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="amount_owned_to_attorney_or_professionals"
                              />
                              Amounts Owed to Attorneys and/or Professionals in
                              this Case
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="contingent_liabilities"
                              />
                              Contingent Liabilities
                            </label>

                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="other_liabilities"
                              />
                              Other Liabilities
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Children’s Assets
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="children_assets"
                              />
                              Children’s Assets
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Property Held in Trust
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="asset_held_for_benefit"
                              />
                              Assets Held by Either Party for the Benefit of
                              Another
                            </label>
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="asset_held_for_either_party"
                              />
                              Assets Held by Another for Either Party
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="shadow mt-3 p-5">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="font-weight-bold h6">
                            Equitable Claims
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <div className="d-flex flex-column">
                            <label>
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="equitable_for_community_estate"
                              />
                              Equitable Claims in Favor of the Marital/Community Estate
                            </label>

                            <label> 
                              <Field
                                className="btn-primary"
                                type="checkbox"
                                name="equitable_for_separate_estate"
                              />
                              Equitable Claims in Favor of Either Party’s Separate Estate
                            </label>
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button
                          id="kt_login_signin_submit"
                          type="submit"
                          className={`btn btn-success font-weight-bold px-9 py-4 my-3 mt-3`}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default EstateCategories;
