import React, {useState, useEffect, useContext} from 'react' 
import ClientWiseSpinner from '../../../_metronic/layout/components/customize/ClientWiseSpinner';
import { Route, Switch } from 'react-router-dom';
import MainTimeline from './_partials/MainTimeline';
import TimelineCard from './_partials/TimelineCard';
import CustomTimeline from './_partials/CustomTimeline';
import CreateTimeline from './Modal/Side/CreateTimeline';

import Axios from 'axios';
import { EncryptStorage } from 'encrypt-storage';
import { SnackBarOptionsContext } from '../../../hooks/ContextStore';
import TimelineLegends from './_partials/TimelineLegends';
import Loading from '../../pages/Loading'
function TimelineForm() {
    const [loading, setLoading]                 = useState();
    const [timelineobj, setTimelineObj]         = useState();
    const encryptStorage                        = new EncryptStorage("secret-key");
    const client_case_id                           = encryptStorage.getItem("client_case_id");   
    const [link, setLink]                       = useState();
    const [downloading, setDownloading]         = useState(false);
    const [snackBarOptions, setSnackBarOptions] = useContext(SnackBarOptionsContext);
    
    const createTimeline =(values)=> {
        setLoading(true);
        Axios.post('api/custom/timeline',values).then((e)=>{ 
            setTimelineObj(timelineobj.concat(e.data.data));
            setLoading(false);
        })
    }

    const updateTimeline =(values)=> {
        setLoading(true);
        Axios.put(`api/custom/timeline/${values.id}`, values).then((e)=>{ 
            let index   = timelineobj.findIndex(e=> e.id === values.id); 
            let tempObj = timelineobj.filter((row) => row.id !== values.id);
            tempObj.splice(index, 0, e.data.data);

            setTimelineObj(tempObj);
            setLoading(false);
        })
    }

    const duplicateTimeline = (values)=> { 
        setLoading(true);
        Axios.post(`api/custom/event/duplicate/${values.id}`, values).then(e=>{ 
            setTimelineObj(timelineobj.concat(e.data.data));
            setLoading(false);
        });
    }

    const deleteTimeline = (id)=> {  
        Axios.delete(`api/custom/timeline/${id}`).then(e=>{  
            window.location.replace(`${window.location.origin}/timeline/main`);

        });
    }

    useEffect(() => { 
        setLoading(true);
        Axios.get(`api/custom/timeline/list/${client_case_id}`)
        .then((e)=>{  
            setTimelineObj(e.data.data);
            setLink(e.data.links.next); 
            setLoading(false);
        });
    }, []);

    const scrollEvent = (event) => {
        (async () => {
          if(link != null) {  
            await Axios.get(link)
                .then(res=>{    
                    setTimelineObj(timelineobj.concat(res.data.data));
                    setLink(res.data.links.next); 
                })
                .finally(()=>{
                })
          }
        })();
    }

    const mainDuplicateTimeline =(values)=> {    
        setLoading(true);
        Axios.post(`api/timeline/event/duplicate`, values).then((e)=>{
            setTimelineObj(timelineobj.concat(e.data.data));
            setLoading(false);
        })
    }
    
    const handleDownload = () => {
        setDownloading(true);
        setSnackBarOptions({
            show: true,
            title: 'Archiving All Timeline Files...'
        });
        Axios.get(`api/v2/files/timeline/batch/download/${client_case_id}`)
        .then(res => {
            window.open(res.data, '_blank');
            setSnackBarOptions({
                show: false,
                title: ''
            });
        })
        .catch(err=>{
            if(err.response.status === 404){
                setSnackBarOptions({
                  show: true,
                  title: "No Files to Download",
                });
              }else{
                setSnackBarOptions({
                  show: true,
                  title: "Unknown Error Occured",
                });
              }
        })
        .finally(()=>{
            setDownloading(false);
        });
        
    };

    return (
        <>
            <div className="col-12 p-5 bg-white border-0 d-flex mb-7 align-items-center rounded">
                <div className="timeline">
                    <h3>Timelines</h3>
                    <h3 className="text-muted font-weight-bold font-size-sm">Case History + Supporting Evidence</h3>
                </div>
                <div className="ml-auto d-flex"> 
                    <CreateTimeline createTimeline={createTimeline}/>
                    <button type="button" className="p-5 ml-3 btn btn-primary d-none d-sm-block" disabled={downloading} onClick={handleDownload}>
                        Download Files {downloading ? <span className="ml-3 spinner spinner-white p-3"></span> : ""}
                    </button>
                </div>
            </div>
            <div id="timelinePage" className="d-flex flex-row">
                <div className="d-flex flex-column" style={{gap: '10px'}}>
                <TimelineLegends />
                <TimelineCard
                    timelineobj={timelineobj}
                    updateTimeline={updateTimeline}
                    duplicateTimeline={duplicateTimeline}
                    mainDuplicateTimeline={mainDuplicateTimeline}
                    deleteTimeline={deleteTimeline}
                    scrollEvent={scrollEvent}
                />
                </div>
               
                <div className="flex-row-fluid ml-lg-8">
                    <Switch>
                        <Route path="/timeline/main" component={()=> <MainTimeline mainDuplicateTimeline={mainDuplicateTimeline}/>}/> 
                        <Route path="/timeline/custom/:id" component={CustomTimeline}/>
                    </Switch>
                </div>
                {loading && <Loading />}
            </div>
        </>
    )
}

export default TimelineForm

