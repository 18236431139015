import Axios from "axios"
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import SheetCard from "./SheetCard"
import ExpenseTable from "./ExpenseTable"
import CreateExpenseDetail from "./CreateExpenseDetail"
import CreateExpense from "./CreateExpense"
import { Button, Divider, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core"; 

export default function ExpenseTracker(){
    const {case_id} = useSelector(state => state.client)
    const [expenses, setExpenses] = useState([])
    const [expenseId, setExpenseId] = useState(0)
    const [exenseDetails, setExpenseDetails] = useState([])
    const [createIsLoad, setCreateIsLoad] = useState(false)
    const [party, setParty] = useState('client')
    const [partyName, setPartyName] = useState('') 
    const [share, setShare] = useState(0)
    const [validShare, setValidShare] = useState(0)
    const [spouseShare, setSpouseShare] = useState(0)
    const appUrl = process.env.REACT_APP_API_URL

    useEffect(()=>{
        Axios
        .get(`api/expense-divider/${case_id}`)
        .then(({data}) => {  
            //set default expense ID
            if(data.data.length > 0) {
                setExpenseId(data.data[0].id)
                setExpenses(data.data) 
                setPartyName(data.data[0][party])
            }
        })
        .catch((err) => {
          console.log(err)
        });
    },[])

    useEffect(() => {
      if(expenseId && expenses.length > 0) {   
        Axios.get(`api/expense-divider/details/${expenseId}`)
            .then(({data}) => {  
                setExpenseDetails(data.data)
                const dynamicShare = parseFloat(expenses?.find(e=> e.id === expenseId)?.share)
                setShare(dynamicShare)  
                setValidShare(!isNaN(parseFloat(dynamicShare)) ? parseFloat(dynamicShare) : 0)
                setSpouseShare(Math.abs((!isNaN(parseFloat(dynamicShare)) ? parseFloat(dynamicShare) : 0) - 100));
            })
            .catch((err) => {
                console.log(err)
            })
      }
    }, [expenseId, expenses])


    const createExpense = (data) => {
        data.client_case_id = case_id;
        Axios.post('api/expense-divider', data)
            .then(({data}) => { 
                if (expenses.length === 0) {
                    setExpenses([data.data])
                    setExpenseId(data.data.id)
                } else {
                    setExpenses(prevValue => [...prevValue, data.data])
                }
            })
    }

    const changeExpenseId = (id) => {
      setExpenseId(id)
    }

    const createExpenseDetail = (data) => {
        data.expense_divider_id = expenseId
        setCreateIsLoad(true)

        Axios.post(`api/expense-divider/details/`, data)
            .then(({data}) => {
                debugger
                setExpenseDetails(prevValue => [...prevValue, data.data])
            })
            .catch((err) => {
            console.log(err)
            })
            .finally(()=> {
                setTimeout(() => {
                    setCreateIsLoad(false)
                }, 1000)
            });
    }

    const updateExpenseDetail = useCallback((id, data) => {
        Axios.put(`api/expense-divider/details/${id}`, data)
            .then(({ data }) => {
                let responseData = data.data;
                let index = exenseDetails.findIndex(e => e.id === responseData.id);
                const updateObject = [...exenseDetails]; // Make a copy of expenseDetails array
                updateObject[index] = responseData;
                setExpenseDetails(updateObject);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [exenseDetails]); // Add expenseDetails as a dependency

    const deleteExpenseDetail = (id) => {
        Axios.delete(`api/expense-divider/details/${id}`)
            .then(()=>{
                const updatedExpenses = exenseDetails.filter(exepense => exepense.id !== id);
                setExpenseDetails(updatedExpenses)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const exportDetails = () => { 
        Axios({
          url: `${appUrl}/expense-divider/details/export/${expenseId}`,
          method: "GET",
          responseType: "blob", // important   
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "expense-detail-report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }

    const exportExpense = () => { 
        Axios({
          url: `${appUrl}/expense-divider/export/${case_id}`,
          method: "GET",
          responseType: "blob", // important   
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "expense-sheet-report.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }); 
    }

    const switchParty = () => {
        if (party === 'client') {
            setParty('spouse')
            setPartyName(expenses?.find(e=> e.id === expenseId)?.spouse )
        } else {
            setParty('client')
            setPartyName(expenses?.find(e=> e.id === expenseId)?.client ) 
        } 
    }

    console.log('validShare', validShare)
    console.log('spouseShare', spouseShare)

    const totalClientExpense = exenseDetails?.reduce((accumulator, currentValue) => {
        if (currentValue.expense_type === 'expense' && expenses?.find(e=> e.id === expenseId)?.client === currentValue.party) {
            return accumulator + parseFloat(currentValue.amount);
        } 
        return accumulator;
    }, 0)
    
    const totalClientPayment = exenseDetails?.reduce((accumulator, currentValue) => {
        if (currentValue.expense_type === 'payment' && expenses?.find(e=> e.id === expenseId)?.client === currentValue.party) {
            return accumulator + parseFloat(currentValue.amount);
        } 
        return accumulator;
    }, 0)

    const totalSpouseExpense = exenseDetails?.reduce((accumulator, currentValue) => {
        if (currentValue.expense_type === 'expense' && expenses?.find(e=> e.id === expenseId)?.spouse === currentValue.party) {
            return accumulator + parseFloat(currentValue.amount);
        } 
        return accumulator;
    }, 0)
    
    const totalSpousePayment = exenseDetails?.reduce((accumulator, currentValue) => {
        if (currentValue.expense_type === 'payment' && expenses?.find(e=> e.id === expenseId)?.spouse === currentValue.party) {
            return accumulator + parseFloat(currentValue.amount);
        } 
        return accumulator;
    }, 0) 
    
    return (
        <div id="expenseTracker" className="row" style={{ gap: '10px'}}> 

                    <>
                    <div className="w-100">
                        <div className="card">
                            <div className="card-body"> 
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="timeline">
                                        <h3 className="m-0 text-dark font-weight-bolder">Transactions</h3>
                                    </div> 
                                    <div className="ml-5"> 
                                        <CreateExpense
                                            createExpense={createExpense}/>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                        <div className="d-flex flex-column" style={{gap: '10px'}}>
                            <SheetCard 
                            expenses={expenses}
                            changeExpenseId={changeExpenseId}
                            expenseId={expenseId}/>
                        </div>
                        <div className="w-100">
                            <div className="card w-100 ml-5 mb-5">
                                <div className="card-header d-flex align-items-center">
                                    <div className="timeline">
                                        <h3 className="m-0 text-dark font-weight-bolder">Expense Divider</h3>
                                    </div> 
                                        <div className="ml-auto"> 
                                            <button 
                                                type="button" 
                                                className="btn btn-primary ml-auto custom-blue-button" 
                                                onClick={exportDetails}>
                                                    EXPORT
                                            </button>
                                        </div>
                                </div>
                            </div> 
                            <div className="col-12 w-100 d-flex justify-content-center ml-5 mb-5">
                                <Button 
                                    className="btn switch type-a w-50"  
                                    onClick={switchParty}
                                >
                                    {`Switch to Party ${party === 'client' ?  expenses?.find(e=> e.id === expenseId)?.spouse ?? '' : expenses?.find(e=> e.id === expenseId)?.client ?? ''}`}
                                </Button>
                            </div>

                                    {
                                        expenses.length !== 0 && (
                                        <>
                                            <div className="card w-100 ml-5 mb-5">
                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center mt-2">
                                                        <CreateExpenseDetail 
                                                            partyName={partyName}
                                                            expenseType={'expense'}
                                                            createExpenseDetail={createExpenseDetail}
                                                            createIsLoad={createIsLoad}/>
                                                        <ExpenseTable 
                                                            expenseType={'expense'}
                                                            exenseDetails={exenseDetails}
                                                            party={partyName}
                                                            updateExpenseDetail={updateExpenseDetail}
                                                            deleteExpenseDetail={deleteExpenseDetail}/>
                                                    </div> 
                                                </div>
                                            </div> 
                                            <div className="card w-100 ml-5 mb-5">
                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center mt-2">
                                                        <CreateExpenseDetail
                                                            partyName={partyName}
                                                            expenseType={'payment'} 
                                                            createExpenseDetail={createExpenseDetail}
                                                            createIsLoad={createIsLoad}/>
                                                        <ExpenseTable 
                                                            expenseType={'payment'}
                                                            party={partyName}
                                                            exenseDetails={exenseDetails}
                                                            updateExpenseDetail={updateExpenseDetail}
                                                            deleteExpenseDetail={deleteExpenseDetail}/>
                                                    </div> 
                                                </div>
                                            </div> 
                                            <div className="card w-100 ml-5">
                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center mt-2">
                                                        <div className="row">
                                                            <h3 className="m-0 text-dark font-weight-bolder">Owed %</h3>
                                                        </div>
                                                        <div className="row mb-5">
                                                                <div className="col-6">
                                                                    <InputLabel htmlFor="outlined-adornment-amount">{expenses?.find(e=> e.id === expenseId)?.client}</InputLabel>
                                                                    <OutlinedInput
                                                                        id="outlined-adornment-amount"
                                                                        startAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                        label="%"
                                                                        value={validShare}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <InputLabel htmlFor="outlined-adornment-amount">{expenses?.find(e=> e.id === expenseId)?.spouse}</InputLabel>
                                                                    <OutlinedInput
                                                                        id="outlined-adornment-amount"
                                                                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                                        label="%"
                                                                        value={spouseShare}
                                                                        />
                                                                </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <h3 className="m-0 text-dark font-weight-bolder">Ammounts Owed</h3>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-6"> 
                                                            <InputLabel htmlFor="outlined-adornment-amount">{expenses?.find(e=> e.id === expenseId)?.client}</InputLabel>
                                                            <OutlinedInput
                                                                id="outlined-adornment-amount"
                                                                startAdornment={<InputAdornment position="end">$</InputAdornment>}
                                                                label="%"
                                                                disabled={true}
                                                                value={(totalClientExpense * (share / 100) - totalClientPayment).toFixed(2)}
                                                            />
                                                            </div>
                                                            <div className="col-6">
                                                                <InputLabel htmlFor="outlined-adornment-amount">{expenses?.find(e=> e.id === expenseId)?.spouse}</InputLabel>
                                                                <OutlinedInput
                                                                    id="outlined-adornment-amount"
                                                                    startAdornment={<InputAdornment position="end">$</InputAdornment>}
                                                                    label="%"
                                                                    disabled={true}
                                                                    value={(totalSpouseExpense * (share / 100) - totalSpousePayment).toFixed(2)}
                                                                /> 
                                                            </div> 
                                                        </div> 
                                                        <div className="row mb-3">
                                                            <h3 className="m-0 text-dark font-weight-bolder">Difference</h3>
                                                        </div> 
                                                        <div className="row mb-3">
                                                            <h5 className="m-0 text-dark font-weight-bolder">{`${ ((totalClientExpense * (share / 100) - totalClientPayment) - ((totalSpouseExpense * (share / 100) - totalSpousePayment))).toFixed(2)}`} Owed to {(totalClientExpense * (share / 100) - totalClientPayment).toFixed(2) > (totalSpouseExpense * (share / 100) - totalSpousePayment).toFixed(2) ? expenses?.find(e=> e.id === expenseId)?.client : expenses?.find(e=> e.id === expenseId)?.spouse}</h5>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div> 
                                        </>
                                        )
                                    }
                        </div>
                    </div>
                    </> 
        </div>
    );
};