import React from "react";
import DocumentsBreadCrumbs from "./components/Breadcrumbs";
import AddFolder from "./components/AddFolder";
import UploadFile from "./components/UploadFile";
import FolderList from "./components/FolderList/FolderList";

export default function DocumentsPage(){
    return(
        <div id="documentsPage" className="row" style={{ gap: '10px'}}>

            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="d-flex flex-column">
                                <h3 className="m-0 text-dark font-weight-bolder">Documents</h3>
                                <span className="text-muted mt-0 font-weight-bold font-size-sm">
                                    Case Related Documents
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card mt-2">
                    <div className="card-body">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row align-items-center">
                                <DocumentsBreadCrumbs />
                                <div className="ml-auto">
                                    <AddFolder />
                                    <UploadFile />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mt-2">
                            <FolderList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};