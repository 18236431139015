import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CreatePublicStock from "./PublicStocksModal/Create";
import EditPublicStock from "./PublicStocksModal/Edit";
import DeletePublicStock from "./PublicStocksModal/Delete";
import CreateEmployeeStockOption from "./EmployeeStockOptionModal/Create";
import EditEmployeeStockOption from "./EmployeeStockOptionModal/Edit";
import DeleteEmployeeStockOption from "./EmployeeStockOptionModal/Delete";
import CreateEmployeeStock from "./EmployeeStockModal/Create";
import EditEmployeeStock from "./EmployeeStockModal/Edit";
import DeleteEmployeeStock from "./EmployeeStockModal/Delete";
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import { EstateContext } from "../../../../hooks/ContextStore";
import moment from "moment";
import ShowNoteComponent from "./ShowNoteComponent";
import FileUpload from "../Components/FileUpload";
import Loading from "../../../pages/Loading"
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function EmployeeStockOptionsRow(props) {
  const { row, setEmployees, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? ""} {moment(row.grant_date).format("L")}
        </TableCell>
     
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditEmployeeStockOption
                setEmployees={setEmployees}
                client_case_id={client_case_id}
                row={row}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteEmployeeStockOption
              client_case_id={client_case_id}
              row={row}
              setEmployees={setEmployees}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'employee-stock-option'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Company :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      # of Options :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.options}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Vesting Schedule :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.vesting_schedule}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Stock Price :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.stock_price}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Strike Price :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.strike_price}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Total Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function PublicStockRow(props) {
  const { row, setPublicStocks, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? ""}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditPublicStock
                client_case_id={client_case_id}
                row={row}
                setPublicStocks={setPublicStocks}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeletePublicStock
              client_case_id={client_case_id}
              row={row}
              setPublicStocks={setPublicStocks}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'public-stocks'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Security :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      # of Shares :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.shares}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EmployeeStockRow(props) {
  const { row, setEmployees, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.name ?? ""} {moment(row.grant_date).format("L")}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditEmployeeStock
                setEmployees={setEmployees}
                client_case_id={client_case_id}
                row={row}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteEmployeeStock
              client_case_id={client_case_id}
              row={row}
              setEmployees={setEmployees}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'employee-stocks'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Company :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      # of Options :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.options}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Vesting Schedule :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.vesting_schedule}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Stock Price :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.stock_price}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Strike Price :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.strike_price}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Total Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EmployeeStock() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_data = () => {
    setLoading(true);
    Axios.get("api/estate/stocks/employee/" + client_case_id)
      .then((res) => {
        setEmployees(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>Employee Stock</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Employee stock, RSUs, ESPPs, Stock Appreciation Rights, etc.
            </span>
          </div>
          <div className="ml-auto">
            <CreateEmployeeStock
              client_case_id={client_case_id}
              setEmployees={setEmployees}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">Debt</TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((row) => (
                <EmployeeStockRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setEmployees={setEmployees}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <Loading />}
      </div>
    </>
  );
}

function EmployeeStockOption() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [employees, setEmployees] = useState([]);

  const fetch_data = () => {
    Axios.get("api/estate/stocks/options/" + client_case_id)
      .then((res) => {
        setEmployees(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>Employee Stock Options</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Employee stock options
            </span>
          </div>
          <div className="ml-auto">
            <CreateEmployeeStockOption
              client_case_id={client_case_id}
              setEmployees={setEmployees}
              employees={employees}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">Debt</TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((row) => (
                <EmployeeStockOptionsRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setEmployees={setEmployees}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function PublicStocks() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [publicStocks, setPublicStocks] = useState([]);

  const fetch_public_stocks = () => {
    Axios.get("api/estate/stocks/public/" + client_case_id)
      .then((res) => {
        setPublicStocks(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    fetch_public_stocks();
  }, []);

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>Publicly traded stocks & other securities</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Stocks, bonds, options, etc. For employee stock, see Categories.
            </span>
          </div>
          <div className="ml-auto">
            <CreatePublicStock
              client_case_id={client_case_id}
              setPublicStocks={setPublicStocks}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">Debt</TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publicStocks.map((row) => (
                <PublicStockRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setPublicStocks={setPublicStocks}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function Stocks() {
  const [category, setCategory] = useContext(EstateContext);
  const data = category != undefined ? category : null;
  const ctgry = data ? true : false;
  let public_stock, stock_options, stock;

  if (ctgry) {
    if (Boolean(data.public_stocks) === true) {
      public_stock = <PublicStocks />;
    }
    if (Boolean(data.employee_stock_option) === true) {
      stock_options = <EmployeeStockOption />;
    }
    if (Boolean(data.employee_stock) === true) {
      stock = <EmployeeStock />;
    }
  }

  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        <div className="card-header border-0 mt-3">
          <h3 class="card-title align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">Stocks</span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Publicly traded stocks, bonds & securities
            </span>
          </h3>
        </div>
        <hr />
        {public_stock}
        {stock_options}
        {stock}
      </div>
    </>
  );
}

export default Stocks;
