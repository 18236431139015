import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../../_metronic/layout";
import { GetCurrentLawfirm } from "../../../Functions/General";
import Axios from "axios";
import DataUsage from "../Chart/DataUsage.js";
import objectPath from "object-path";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";
import ApexCharts from "apexcharts";

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  
const getChartOptions = (layoutProps, height) => {
    const options = {
      series: [50],
      chart: {
        height: height,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700",
            },
            value: {
              color: layoutProps.colorsGrayGray700,
              fontSize: "30px",
              fontWeight: "700",
              offsetY: 12,
              show: true,
            },
          },
          track: {
            background: "red",
            strokeWidth: "100%",
          },
        },
      },
      colors: ["#007bff"],
      stroke: {
        lineCap: "round",
      },
      labels: ["Progress"],
    };
    return options;
};

export default function DataUsageComponent(){
  const lawfirm = GetCurrentLawfirm();
  const [storage, setStorage] = useState(0);
  const [kb, setKb] = useState(0);
  const [mb, setMb] = useState(0);
  let unlispace = 102498975;

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  useEffect(() => {
    Axios.get(
      `api/lawfirm/usage/${lawfirm.id}`
    ).then((res) => {
      setStorage(res.data.data[0].gigaByte_available);
    });

    Axios.get(
      `api/lawfirm/usage/total/${lawfirm.id}`
    ).then(async (res) => {
      let cases = res.data.data[0].cases;
      if (cases.length !== 0) {
        let caseFilesSize = [];
        let caseFiles = [];
        await asyncForEach(cases, async (clientCase) => {
          caseFiles.push(clientCase.files);
          await asyncForEach(clientCase.files, (caseFile) => {
            caseFilesSize.push(caseFile.size);
          });
        });
        setKb(
          caseFilesSize.reduce((partialSum, a) => partialSum + a, 0) / 1024
        );
        setMb(
          caseFilesSize.reduce((partialSum, a) => partialSum + a, 0) /
            1024 /
            1024
        );
      }
    });
  }, []);

  return (
    <div className={`card h-100 card-custom`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">Data Usage</h3>
        <div className="card-toolbar">
        </div>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div
            style={{
              height: "200px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "5px",
            }}
          >
            <DataUsage
              percent={
                storage === unlispace
                  ? 100
                  : (((storage - mb) / storage) * 100).toFixed(2)
              }
            />
          </div>
        </div>
        <div className="pt-5">
          <div className="d-flex justify-content-between">
            <div>
              <i className="fas fa-square text-primary ml-3"></i>
              &nbsp;&nbsp;&nbsp;<label>Total Space</label>
            </div>
            <div>
              <label>
                {storage === unlispace ? (
                  <i class="fas fa-infinity"></i>
                ) : (
                  (storage / 1024).toFixed(0) + "GB"
                )}
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <i className="fas fa-square text-danger ml-3"></i>
              &nbsp;&nbsp;&nbsp;<label>Used Space</label>
            </div>
            <div>
              <label>{mb.toFixed(2)} MB</label>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <i className="fas fa-square text-warning ml-3"></i>
              &nbsp;&nbsp;&nbsp;<label>Remaining Space</label>
            </div>
            <div>
              <label>
                {storage === unlispace ? (
                  <i class="fas fa-infinity"></i>
                ) : (
                  ((storage - mb) / 1024).toFixed(0) + "GB"
                )}
              </label>
            </div>
          </div>
          <a
            href="/subscription"
            className="btn btn-primary btn-shadow-hover font-weight-bolder w-100 py-5 mt-5"
          >
            Upgrade My Plan
          </a>
        </div>
      </div>
    </div>
  );
};