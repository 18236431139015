import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {  Route, Switch } from "react-router-dom";
import { EncryptStorage } from "encrypt-storage";
import Link from '@material-ui/core/Link';
import Axios from "axios";
import DocumentsFileTree from "./_partials/DocumentsFileTree/DocumentsFileTree";

function DocumentsCard(){
    return(
        <>
            <div className="flex-row-auto offcanvas-mobile" id="kt_profile_aside">
                <div className="card card-custom card-stretch">
                    <div className="card-body pt-4">
                    <div className="d-flex justify-content-start my-5">
                        <h3>Documents</h3>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                        <DocumentsFileTree />
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DocumentsCard