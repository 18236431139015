import React,{useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    IconButton,
    TextField,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios';
import { useFormik } from 'formik';
import * as yup from "yup";

const validationSchema = yup.object({

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate propertyfield must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Client’s separate property is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Spouse’s separate property is required!"),


  value: yup.number().test(
    "maxDigits",
    "Total amount field must have 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Total amount is required!")

  
});

export default function Edit(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {row,setCashes,client_case_id} = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (values) => {
    const {...data} = values;
      setLoading(true);

      const response = await Axios
        .put(`api/estate/cash/onhand/cashes/${row.id}`, data)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setLoading(false);
        setCashes(response.data.data)
      }
    };

    const formik = useFormik( {initialValues: 
      { 
          client_case_id:client_case_id,
          description:row.description,
          county:row.county,
          value:row.value,
          other_liens:row.other_liens,
          client_sp:row.client_sp,
          spouse_sp:row.spouse_sp,
          to_client:row.couto_clientnty,
          notes:row.notes,
      },
    validateOnBlur:true,
    onSubmit,
    validationSchema: validationSchema
   });

    return (
        <div>
          <div className="card-toolbar"> 
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton> 
            </div>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Equivalent"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                  <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      {/* <Form.Label>Location (e.g. “Wife’s home”, “Safety deposit box”, etc.)</Form.Label> */}
                      <Form.Label>Description</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="description"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.description}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.description ? formik.errors.description : ""}
                        </div>
                     </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Total amount</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="valuefff"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.value ? formik.errors.value : ""}
                        </div>
                     </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="client_sp"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                     </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                     </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="notes"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                     </div>
                    </div>

                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                    <span>Submit</span>
                    {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      );
}