import React,{useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    IconButton,
    TextField,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import Axios from 'axios';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";

import * as yup from "yup";

const validationSchema = yup.object({
  
  options: yup.number().test(
    "maxDigits",
    "Number of options field must be 10 digits or less",
    (options) => String(options).length <= 10
  )
  .required("Number of options is required!"),
  
  stock_price: yup.number().test(
    "maxDigits",
    "Current stock price field must be 10 digits or less",
    (stock_price) => String(stock_price).length <= 10
  )
  .required("Current stock price is required!"),

  strike_price: yup.number().test(
    "maxDigits",
    "Strike price field must be 10 digits or less",
    (strike_price) => String(strike_price).length <= 10
  )
  .required("Strike price  is required!"),

  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate property must be 10 digits or less",
    (client_sp) => String(client_sp).length <= 10
  )
  .required("Client’s separate propertyis required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property must be 10 digits or less",
    (spouse_sp) => String(spouse_sp).length <= 10
  )
  .required("Spouse’s separate property propertyis required!"),

  value: yup.number().test(
    "maxDigits",
    "Market value field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Market value is required!")
});
export default function Edit(props){

    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {row,setEmployees,client_case_id} = props;
    const [accountBalanceDate, setAccountBalanceDate] = useState(new Date(row.value_date));
    const [grantDate, setGrantDate] = useState(new Date(row.grant_date));

    const handleAccountDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setAccountBalanceDate(new_date);
    };

    const handleGrantDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setGrantDate(new_date);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          grant_date:grantDate,
          value_date:accountBalanceDate,
          name:values.name,
          vesting_schedule:values.vesting_schedule,
          options:values.options,
          stock_price:values.stock_price,
          strike_price:values.strike_price,
          value:values.value,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .put("api/estate/stocks/options/"+row.id, formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setEmployees(response.data.data);
        // formik.resetForm();
        setTimeout(()=>{
          setLoading(false)
        },1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        name:row.name,
        vesting_schedule:row.vesting_schedule,
        options:row.options,
        stock_price:row.stock_price,
        strike_price:row.strike_price,
        value:row.value,
        other_liens:row.other_liens,
        client_sp:row.client_sp,
        spouse_sp:row.spouse_sp,
        to_client:row.to_client,
        cost:row.cost,
        notes:row.notes
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
    });



    return (
        <div>
          <div className="card-toolbar"> 
              <IconButton aria-label="update" onClick={handleClickOpen}>
                  <EditIcon />
              </IconButton> 
          </div>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Employee Stock Options"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of company</Form.Label>
                      <input
                        type="text"
                        name="name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name ? formik.errors.name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Date of grant</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}  utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={grantDate}
                              onChange={handleGrantDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Please describe vesting schedule</Form.Label>
                      <input
                        type="text"
                        name="vesting_schedule"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.vesting_schedule}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.vesting_schedule ? formik.errors.vesting_schedule : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Number of options</Form.Label>
                      <input
                        type="number"
                        name="options"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.options}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.options ? formik.errors.options : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current stock price</Form.Label>
                      <input
                        type="number"
                        name="stock_price"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.stock_price}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.stock_price ? formik.errors.stock_price : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Strike price</Form.Label>
                      <input
                        type="number"
                        name="strike_price"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.strike_price}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.strike_price ? formik.errors.strike_price : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current net market value as of</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}  utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={accountBalanceDate}
                              onChange={handleAccountDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <Form.Label>Current net market value</Form.Label>
                      <input
                        type="number"
                        name="value"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value ? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                 
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      );
}