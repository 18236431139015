import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumb, setCanCreateFolder, setCanUploadFile, setCurrentList, setLoading, setPrevList } from "../../../../redux/slices/documentsPageSlice";
import Axios from "axios";
import { GetClientCaseId } from "../../../../Functions/General";
import Chip from '@material-ui/core/Chip';
export default function DocumentsBreadCrumbs(){
    const documentsPage = useSelector(state => state.documentsPage);
    const dispatch = useDispatch();
    const client_case_id = GetClientCaseId();
    
    const handleReturnBreadcrumbs = (breadcrumb) => {
        let breadCrumbs = documentsPage.breadCrumb;
        let breadCrumbsIndex = breadCrumbs.findIndex(item => item.section_id === breadcrumb.section_id);

        if (breadCrumbsIndex !== -1) {
            breadCrumbs = breadCrumbs.slice(0, breadCrumbsIndex + 1);
            dispatch(setBreadCrumb(breadCrumbs));
        }
        
        if(breadcrumb.section_id === 'my-documents'){
            dispatch(setCurrentList(documentsPage.myDocumentsList));
            dispatch(setCanCreateFolder(true));
            dispatch(setCanUploadFile(false));
            return;
        }
        dispatch(setLoading(true));
        
        var formData = {
            section_id: breadcrumb.section_id,
            client_case_id: client_case_id,
            section_name: breadcrumb.section_name,
            parent: breadcrumb.parent || 0,
            source: breadcrumb.section_id || breadcrumb.source,
            source_id: breadcrumb.source_id || 1,
        };
        
        Axios.post('/api/v2/documents/folder/content', formData)
        .then(({data}) => {
            dispatch(setPrevList(documentsPage.currList));
            dispatch(setCurrentList(data));
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
    };
    
    return(
        <Breadcrumbs aria-label="breadcrumb">
            {documentsPage.breadCrumb.map((breadcrumb, index) => (
                <Link key={index} color="inherit" aria-current="page" onClick={() => handleReturnBreadcrumbs(breadcrumb)}>
                    <span className="navi-text font-size-lg text-capitalize text-dark">
                         {index + 1 === documentsPage.breadCrumb.length? 
                             <Chip className="pt-5 pb-5 fs-1" label={breadcrumb.section_name}/> 
                            :
                             <span className="navi-text font-size-lg text-capitalize text-dark">{breadcrumb.section_name}</span>
                            }
                    </span>
                </Link>
            ))}
        </Breadcrumbs>
    );
};