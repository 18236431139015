import React from "react";
import { Switch } from "react-router-dom"; 
import BasicCard from "./BasicCard";
import ClientInformation from "../Basics/_partials/ClientInformation";
import OpposingParty from "./_partials/OpposingParty";
import ChildrenInvolve from "./_partials/ChildrenInvolve";
import MarriageSeparation from "./_partials/MarriageSeparation";
import PendingSuit from "./_partials/PendingSuit";
import CourtOrder from "./_partials/CourtOrder";
import GovernmentEntities from "./_partials/GovernmentEntities";
import AttorneyRecord from "./_partials/AttorneyRecord";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { ContentRoute } from "../../../_metronic/layout";

export default function Basics() {
    const encryptStorage = new EncryptStorage("secret-key");
    const decypt_data = encryptStorage.getItem("case_details");
    const data = decypt_data;
    const client_case_id = data ? data?.case?.id : null;

    const handleExportBasic = (event) => {
        event.preventDefault();
          Axios({
            url: process.env.REACT_APP_API_URL +'/export/basics/'+client_case_id,
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download','export-basics.xlsx'); //or any other extension
             document.body.appendChild(link);
             link.click();
          });
    };

    return (
    <div id="basics-page" className="d-flex flex-row flex-wrap">
        <div className="col-12 p-6 bg-white border-0 d-flex mb-7 align-items-center rounded">
            <div className="timeline">
                <h3>Basics</h3>
                <h3 className="text-muted font-weight-bold font-size-sm">Case Basic Information</h3>
            </div>
            <div className="ml-auto"> 
                <button className="btn btn-primary" onClick={handleExportBasic}>EXPORT TO EXCEL</button>
            </div>
        </div>
        <BasicCard/>
        <div className="flex-row-fluid">
        <Switch>

            <ContentRoute
                path="/basics/client-information"
                component={ClientInformation}
            />
           
        
            <ContentRoute
                path="/basics/opposing-party/"
                component={OpposingParty}
            />

            <ContentRoute
                path="/basics/children-involve/"
                component={ChildrenInvolve}
            />

            <ContentRoute
                path="/basics/marriage-separation/"
                component={MarriageSeparation}
            />
            <ContentRoute
                path="/basics/pending-suit/"
                component={PendingSuit}
            />
            <ContentRoute
                path="/basics/court-order/"
                component={CourtOrder}
            />
            <ContentRoute
                path="/basics/attorney-record/"
                component={AttorneyRecord}
            />
            <ContentRoute
                path="/basics/government-entities"
                component={GovernmentEntities}
            />
        </Switch>
        </div>
      </div>
    )
}

