import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@material-ui/core";
import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { GetClientCaseId, GetUserId } from "../../../../Functions/General";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { setCurrentList, setLoading } from "../../../../redux/slices/documentsPageSlice";

const UploadFieldStyle = {
     flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const mytextcolor = {
    color: 'rgba(0, 0, 0, 0.54)'
  };
const getFileIcon = (type) => {
    switch (type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/jpg':
            return <i className="p-3 fas fa-2x fa-image mr-2"></i>;
        case "application/pdf":
            return <i className="p-3 fas fa-2x fa-file-pdf mr-2"></i>;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return <i className="p-3 fas fa-2x fa-file-word mr-2"></i>;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return <i className="p-3 fas fa-2x fa-file-excel mr-2"></i>;
        case "video/mp4":
        case "video/mov":
            return <i className="p-3 fas fa-2x fa-file-video mr-2"></i>;
        default:
            return <i className="p-3 fas fa-2x fa-question-circle mr-2"></i>;
    }
};

export default function UploadFile(){
    const documentsPage = useSelector(state => state.documentsPage);
    const client_case_id = GetClientCaseId();
    const canUploadFile = documentsPage.canUploadFile;
    const curFolder = documentsPage.curFolder;
    const user_id = GetUserId();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploading, setUploading] = useState(false);
    const uploadLimit = {count: 50, size: 200};

    const handleOpenModal = () => {
        formik.resetForm();
        setFiles([]);
        setOpen(!open);
        setUploadProgress({});
    };

    const handleRemoveFile = (fileIndex) => {
        setFiles(files.filter((_, index)=> index !== fileIndex));
    };

    const acceptedFiles = useMemo(() => {
        return files;
    },[files]);

    const fileSizeCount = useMemo(() => {
        let totalFilesSize = acceptedFiles.reduce((sum, current) => {
            return sum + current.size;
        },0);
        let toKB            = totalFilesSize / 1024;
        let toMB            = toKB / 1024;
        return toMB;
    }, [acceptedFiles]);

    const handleAcceptFile = (e) => {
        setFiles([...files, ...e.target.files]);
    };

    const handleSubmit = async () => {
        setUploading(true);
        const filePromises = acceptedFiles.map((file) => {
            const formData = new FormData();
            formData.append('file[]', file);
            formData.append('case_id', client_case_id);
            formData.append('source', curFolder.source);
            formData.append('source_id', curFolder.source_id);
            formData.append('uploaded_by', user_id);

            return Axios.post('/api/v2/documents/file/add', formData, {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round ((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
                }
            })
        })
        try {
            await Promise.all(filePromises);
            var formData = {
                section_id: curFolder.source,
                source: curFolder.source,
                source_id: curFolder.source_id || 1,
                client_case_id: client_case_id,
                section_name: curFolder.source,
                parent: curFolder.Parent
            };
            Axios.post('/api/v2/documents/folder/content', formData)
            .then(({data}) => {
                dispatch(setCurrentList(data));
                handleOpenModal();
            })
            .finally(() => {
                dispatch(setLoading(false));
                setUploading(false);
            });
        } catch (error) {

        }
    };

    const formik = useFormik({
        initialValues:{

        },
        onSubmit: handleSubmit
    });

    useEffect(() => {
        if(fileSizeCount > uploadLimit.size || acceptedFiles.length > uploadLimit.count){
            setUploading(true);
        }
    }, [files]);

    return(
        <>
        {canUploadFile && (
            <button className="btn btn-primary mr-3 px-5 py-3 my-3" onClick={handleOpenModal}>
                <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
                </span>
                Upload Files
            </button>
        )}
        <Dialog open={open} maxWidth="sm" fullWidth scroll="paper">
            <DialogTitle>File Upload</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
            <DialogContent dividers>
                    <div className="d-flex flex-column m-8">
                        <label htmlFor="file-upload" style={UploadFieldStyle}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Upload.svg")}></SVG>
                            <p className="m-0">Drag n' Drop some files here, or click to select files.</p>
                        </label>
                        <input id="file-upload" type="file" className="d-none" onChange={handleAcceptFile} multiple disabled={uploading}/>
                        <div className="d-flex flex-row mt-6 w-100">
                            <h4 className={`m-0 ${acceptedFiles.length > 24 && 'text-danger'}`} style={mytextcolor}>Files{`(${Number(acceptedFiles.length)}/${uploadLimit.count})`}</h4>
                            <h4 style={mytextcolor} className="ml-auto m-0">{`${fileSizeCount.toFixed(2)}/${uploadLimit.size}MB`}</h4>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            {acceptedFiles.map((file, index) => (
                                 <div key={index} className="my-2 d-flex flex-row flex-wrap shadow w-100 p-2 align-items-center border border-black">
                                    {getFileIcon(file.type)}
                                    <h5 style={{marginTop: "7px"}}>{String(file.name).slice(1, 16)}...</h5>
                                    <div className="ml-auto">
                                        {uploadProgress[file.name] !== 100 ? 
                                            <i className="fas fa-spin text-muted mr-2"></i>
                                        :
                                            <i className="fas fa-check text-success mr-2"></i>
                                        }
                                        {!uploading &&
                                            <i className="fas fa-trash text-danger mr-2" onClick={() => handleRemoveFile(index)}></i>
                                        }
                                    </div>
                                    <LinearProgress className="col-12" variant="determinate" value={uploadProgress[file.name] || 0}/>
                                </div>
                            ))}
                        </div>
                    </div>
            </DialogContent>
            <DialogActions className="justify-content-start">
                <div className="d-flex flex-row p-3">
                    <Button type="submit" variant="contained" className="btn btn-md btn-success px-9 py-4 my-3" disabled={uploading}>
                        Submit
                    </Button>
                    <Button variant="contained" className="ml-3 btn btn-md custom-cancel-button px-9 py-4 my-3" onClick={handleOpenModal} disabled={uploading}>
                        Cancel
                    </Button>
                </div>
            </DialogActions>
            </form>
        </Dialog>
        </>
    );
};