import React, { useEffect, useState, useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles'; 
import { Box, Collapse, IconButton } from '@material-ui/core'; 
import { blue } from '@material-ui/core/colors';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import NumberFormat from 'react-number-format'; 
import { EstateContext } from '../../../../../hooks/ContextStore';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function GrandTotal({division}){
    const [open, setOpen]                             = useState(false);
    const classes                                     = useRowStyles();
    const mobile                                      = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]                     = useState(mobile.matches); 
    const [netValue, setNetValue]                     = useState();
    const [debt, setDebt]                             = useState();
    const [assetValue, setAssetValue]                 = useState();
    const [clientSp, setClientSP]                     = useState();
    const [spouseSp, setSpouseSP]                     = useState();
    const [toClient, setToClient]                     = useState();
    const [toSpouse, setToSpouse]                     = useState();
    const [spousePrntg, setSpousePrntg]               = useState();
    const [clientPrntg, setClientPrntg]               = useState();
    const [category, setCategory]                     = useContext(EstateContext);
    const data                                        = category ? category : [];
    
    const initialVal = () => {
        return {
        client_case_id: data ? data.client_case_id : false,
        real_property: data ? Boolean(data.real_property) : false,
        mineral_interest: data ? Boolean(data.mineral_interest) : false,
        cash: data ? Boolean(data.cash) : false,
        cash_equivalent: data ? Boolean(data.equivalents) : false,
        financial_institution: data ? Boolean(data.bank_account) : false,
        brokerage: data ? Boolean(data.brokerage) : false,
        public_stock: data ? Boolean(data.public_stocks) : false,
        stocks_option: data ? Boolean(data.employee_stock_option) : false,
        employee_stock: data ? Boolean(data.employee_stock) : false,
        bonus: data ? Boolean(data.bonuses) : false,
        closely_held_business: data ? Boolean(data.business_interest) : false,
        defined_contribution_plan: data ? Boolean(data.contribution_plan) : false,
        defined_benefit_plan: data ? Boolean(data.benefit_plan) : false,
        ira_sep: data ? Boolean(data.ira_sep) : false,
        military_benefit: data ? Boolean(data.military_benefit) : false,
        non_qualified_plan: data ? Boolean(data.non_qualified_plan) : false,
        government_benefit: data ? Boolean(data.government_benefits) : false,
        other_deferred_benefit: data ? Boolean(data.compensation_benefits) : false,
        union_benefits: data ? Boolean(data.union_benefits) : false,
        life_insurance: data ? Boolean(data.life_insurance) : false,
        annuity: data ? Boolean(data.annuities) : false,
        vehicles: data ? Boolean(data.vehicles) : false,
        money_owed: data ? Boolean(data.money_owned) : false,
        client_possession: data ? Boolean(data.client_possession) : false,
        spouse_possession: data ? Boolean(data.spouse_possession) : false,
        both_possession: data ? Boolean(data.spouse_both) : false,
        pets: data ? Boolean(data.pets) : false,
        live_stock: data ? Boolean(data.livestock) : false,
        club_membership: data ? Boolean(data.club_membership) : false,
        travel_award_benefit: data ? Boolean(data.travel_award_benefit) : false,
        intellectual_property: data ? Boolean(data.intellectual_property) : false,
        precious_metals: data ? Boolean(data.precious_metals) : false,
        safe_deposit_box: data ? Boolean(data.safe_deposit) : false,
        contingent_assets: data ? Boolean(data.contingent_assets) : false,
        other_assets: data ? Boolean(data.other_assets) : false,
        storage_facility: data ? Boolean(data.storage_facilities) : false,
        credit_card: data ? Boolean(data.credit_card) : false,
        tax_liabilities: data ? Boolean(data.federal_liabilities) : false,
        contigent_liabilities: data
            ? Boolean(data.contingent_liabilities)
            : false,
        other_liabilities: data ? Boolean(data.other_liabilities) : false,
        child_account: data ? Boolean(data.children_assets) : false,
        asset_held_for_benefit: data
            ? Boolean(data.asset_held_for_benefit)
            : false,
        asset_held_for_either_party: data
            ? Boolean(data.asset_held_for_either_party)
            : false,
        equitable_claim_marital: data
            ? Boolean(data.equitable_for_community_estate)
            : false,
        equitable_claim_either: data
            ? Boolean(data.equitable_for_separate_estate)
            : false,
        amount_owed: data
            ? Boolean(data.amount_owned_to_attorney_or_professionals)
            : false,
        };
    };

    useEffect(()=>{
        var assocCollection = [];
        let selectedAssoc   = ['payment_between_parties'];

        for(const [key, value] of Object.entries(initialVal())){
            if(value){
                selectedAssoc.push(key);
            }
        }

        var index = 0;
        for(var key in division) {
            if(selectedAssoc.includes(key)){
                assocCollection[index] = key;
                index = index + 1;
            }
        }

        var net_value     = 0;
        var asset_value   = 0;
        var debt          = 0;
        var client_sp     = 0;
        var spouse_sp     = 0;
        var to_client     = 0;  
        
        assocCollection.map((assoc, index)=>{
            let liabilities      = ['credit_card', 'tax_liabilities', 'amount_owed', 'other_liabilities'];
            let special          = ['precious_metals'];
            let specialLiabities = ['contigent_liabilities'];
            
            if(assoc === 'vehicles'){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('balance_loan'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
            }
            
            if(specialLiabities.includes(assoc)){
                net_value    = net_value   + -Math.abs(parseFloat(division[assoc].sum('net_value')));
                // asset_value  = asset_value + parseFloat(division[assoc].sum('value'));
                debt         = debt        + parseFloat(division[assoc].sum('amount'));
                client_sp    = client_sp   + -Math.abs(parseFloat(division[assoc].sum('client_sp')));
                spouse_sp    = spouse_sp   + -Math.abs(parseFloat(division[assoc].sum('spouse_sp')));
                to_client    = to_client   + -Math.abs(parseFloat(division[assoc].sum('to_client')));
                return
            }
            
            if(special.includes(assoc)){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('debt'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
                return
            }

            if(liabilities.includes(assoc)){
                net_value    = net_value   + -Math.abs(parseFloat(division[assoc].sum('net_value')));
                // asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + Math.abs(parseFloat(division[assoc].sum('value')));
                client_sp    = client_sp   + -Math.abs(parseFloat(division[assoc].sum('client_sp')));
                spouse_sp    = spouse_sp   + -Math.abs(parseFloat(division[assoc].sum('spouse_sp')));
                to_client    = to_client   + -Math.abs(parseFloat(division[assoc].sum('to_client')));
                return
            }

            if(!liabilities.includes(assoc) && assoc !== 'contigent_liabilities' && assoc !== 'precious_metals' && assoc !== 'vehicles'){
                net_value    = net_value   + parseFloat(division[assoc].sum('division_net_value'));
                asset_value  = asset_value + parseFloat(division[assoc].sum('asset_value'));
                debt         = debt        + parseFloat(division[assoc].sum('debt'));
                client_sp    = client_sp   + parseFloat(division[assoc].sum('client_sp'));
                spouse_sp    = spouse_sp   + parseFloat(division[assoc].sum('spouse_sp'));
                to_client    = to_client   + parseFloat(division[assoc].sum('to_client'));
                return
            }
        });

        var clientPrcnt = Math.round(to_client / net_value * 100 * 100) / 100;
        var spousePrcnt = Math.round((100 - clientPrcnt) * 100) / 100;
        setAssetValue(Math.round(asset_value * 100) / 100);
        setDebt(Math.round(debt * 100) / 100);
        
        //**Original Formula */
        setNetValue(Math.round(net_value * 100) / 100);
        
        //**Hacked Formula */
        //setNetValue(Math.round((asset_value - debt) - (client_sp + spouse_sp) * 100) / 100);
        
        setClientSP(Math.round(client_sp * 100) / 100);
        setSpouseSP(Math.round(spouse_sp * 100) / 100);
        setToClient(Math.round(to_client * 100) / 100); 
        setToSpouse(Math.round((net_value - to_client) * 100) / 100); 
        setClientPrntg(clientPrcnt);
        setSpousePrntg(spousePrcnt);

    },[division, category, initialVal()])

    Array.prototype.sum = function (prop) {
        var total = 0
        for ( var i = 0, _len = this.length; i < _len; i++ ) {
            total += isNaN(parseFloat(this[i][prop])) ? 0 : parseFloat(this[i][prop]);
        }
        return total
    }
    
    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    
    }, []);
    
    return(
        <>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell className="text-uppercase text-muted">Value</TableCell>
                <TableCell align="left" className="text-uppercase text-muted">DEBT</TableCell>
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">CLIENT SP</TableCell> : null }
                {!isMobile ? <TableCell className="text-uppercase text-muted">SPOUSE SP</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">NET VALUE</TableCell> : null } 
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">SPLIT</TableCell> : null } 
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO CLIENT</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO SPOUSE</TableCell> : null }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.root}>
                    {isMobile ? 
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon style={{color: blue}}/> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell> 
                    : null }
                    <TableCell className="font-weight-lighter fs-1">
                        <NumberFormat value={assetValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </TableCell>
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={debt} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={clientSp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={spouseSp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={netValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            {`${!isFinite(clientPrntg) ? 0 : clientPrntg}% - ${!isFinite(spousePrntg) ? 0 : spousePrntg}%`}
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={toClient} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    {!isMobile ? 
                        <TableCell className="font-weight-lighter fs-1">
                            <NumberFormat value={toSpouse} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell> 
                    : null }
                    </TableRow>
                    <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                            <div className="shadow mb-3">
                            <List>
                                <ListItem button>
                                    <ListItemText className="font-weight-lighter fs-1">
                                    CLIENT SP :
                                    </ListItemText>
                                    <ListItemSecondaryAction className="font-weight-lighter fs-1">
                                        <NumberFormat value={clientSp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem button>
                                    <ListItemText className="font-weight-lighter fs-1">
                                    SPOUSE SP :
                                    </ListItemText>
                                    <ListItemSecondaryAction className="font-weight-lighter fs-1">
                                        <NumberFormat value={spouseSp} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem button>
                                    <ListItemText className="font-weight-lighter fs-1">
                                    NET VALUE :
                                    </ListItemText>
                                    <ListItemSecondaryAction className="font-weight-lighter fs-1">
                                        <NumberFormat value={netValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider /> 
                                <ListItem button>
                                    <ListItemText className="font-weight-lighter fs-1">
                                    TO CLIENT :
                                    </ListItemText>
                                    <ListItemSecondaryAction className="font-weight-lighter fs-1">
                                        <NumberFormat value={toClient} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                                <ListItem button>
                                    <ListItemText className="font-weight-lighter fs-1">
                                    TO SPOUSE :
                                    </ListItemText>
                                    <ListItemSecondaryAction className="font-weight-lighter fs-1">
                                        <NumberFormat  value={toSpouse} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                            </div>
                            </Box>
                        </Collapse>
                    </TableCell>
                    </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default GrandTotal