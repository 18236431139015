import React,{useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import Axios from 'axios';

export default function EditProperty(props){
    const [open, setOpen]                               = useState(false);
    const [loading,setLoading]                          = useState(false);
    const {row,setProperties,client_case_id}            = props;
    const [marketValueDate, setMarketValueDate]         = useState(new Date(row.value_date));
    const [mortgageBalanceDate, setmortgageBalanceDate] = useState(new Date(row.mortgage_date));
    
    const handleMarketValueDateChange = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setMarketValueDate(new_date);
      };
      
    const handleMortgageBalanceDateChange = (date) => {
        let date_dt = new Date(date);
        let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
        setmortgageBalanceDate(new_date);
    };
      
    const onSubmit = async (values) => {
        const data = {
          client_case_id:client_case_id,
          street_address:values.street_address,
          county:values.county,
          value:values.value,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          notes:values.notes,
          mortgage: values.mortgage,
          mortgage_date:marketValueDate,
          value_date:mortgageBalanceDate
        };
  
        setLoading(true);
        const response = await Axios
          .put(`api/estate/real/property/${row.id}`, data)
          .catch((err) => {
            if (err && err.response) console.log("Error", err);
          });
    
        if (response) {
          setOpen(false);
          setLoading(false);
          setProperties(response.data.data)
        }
    };
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: { 
          street_address:row.street_address,
          county:row.county,
          value:row.value,
          other_liens:row.other_liens,
          client_sp:row.client_sp,
          spouse_sp:row.spouse_sp,
          to_client:row.couto_clientnty,
          cost:0,
          mortgage:row.mortgage,
          notes:row.notes,
      },
    validateOnBlur:true,
    onSubmit
   });
   
   return (
        <div>
        <div className="card-toolbar"> 
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton> 
            </div>
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Real Property"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Street address (or if not address, legal description)</Form.Label>
                    <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="street_address"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.street_address}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.street_address ? formik.errors.street_address : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>County of location</Form.Label>
                    <input
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="county"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.county}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.county? formik.errors.county : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Current fair market value as of</Form.Label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}   utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={marketValueDate}
                            onChange={handleMarketValueDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                    </MuiPickersUtilsProvider>

                    <Form.Label>Current fair market value</Form.Label>
                    <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="value"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value? formik.errors.value : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Current balance of mortgage as of</Form.Label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}   utils={MomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={mortgageBalanceDate}
                            onChange={handleMortgageBalanceDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                    </MuiPickersUtilsProvider>
                    
                    <Form.Label>Current balance Mortgage</Form.Label>
                    <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="mortgage"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.mortgage}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.mortgage? formik.errors.mortgage : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Other liens against property</Form.Label>
                    <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="other_liens"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.other_liens}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.other_liens? formik.errors.other_liens : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Client’s separate property</Form.Label>
                    <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="client_sp"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp? formik.errors.client_sp : ""}
                        </div>
                    </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                    <Form.Label>Spouse’s separate property</Form.Label>
                    <input
                        type="number"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        name="spouse_sp"
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                    />
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp? formik.errors.spouse_sp : ""}
                        </div>
                    </div>
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <Form.Label>Notes</Form.Label>
                        <input
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 `}
                            name="notes"
                            onChange={formik.handleChange}
                            onblur={formik.handleBlur}
                            value={formik.values.notes}
                        />
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                            {formik.errors.notes? formik.errors.notes : ""}
                            </div>
                        </div>
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    >
                        <span>Submit</span>
                    {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                    )}
                    </button>
                    </div>
                </form>
                </div>
            </DialogContentText>
            </DialogContent>
        </Dialog>
        </div>
    );
};