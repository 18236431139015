import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ClientWiseSpinner from "../../../../_metronic/layout/components/customize/ClientWiseSpinner";
import FileUpload from "../Components/FileUpload";
import { PlaidLinkComponent } from "../PlaidLinkComponent";
import CreateBankAccount from "./BankAccountsModal/Create";
import DeleteBankAccount from "./BankAccountsModal/Delete";
import EditBankAccount from "./BankAccountsModal/Edit";
import ShowNoteComponent from "./ShowNoteComponent";
// import { BankAccountContext } from "../../../../hooks/ContextStore";
import ClientWiseAlert from "../../../../_metronic/layout/components/customize/Alert";
import Loading from "../../../pages/Loading";

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function BankAccountRow(props) {
  const { row, setBanks, client_case_id } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
            {`${row.institution_name} ${row.type?.charAt(0).toUpperCase() + row.type?.slice(1)} ${row.account_number}`}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.balance ? `$${row.balance} as of (${moment(row.balance_date).format("L")})` : "0"}
          </TableCell>
          {/* {!isMobile ? <TableCell align="left" className="font-weight-bold fs-1">${row.other_liens}</TableCell> : null } */}
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.client_sp ? `$${row.client_sp}` : "0"}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.spouse_sp ? `$${row.spouse_sp}` : "0"}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            {row.net_value ? `$${row.net_value}` : "0"}
          </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditBankAccount
                row={row}
                setBanks={setBanks}
                client_case_id={client_case_id}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteBankAccount
              row={row}
              setBanks={setBanks}
              client_case_id={client_case_id}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload
              row={row}
              client_case_id={client_case_id}
              source={"bank-account"}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Bank :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.institution_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Account # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.account_number}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Account Type:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.type}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name(s) on Account :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.account_name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.balance} as of ({moment(row.blance_date).format("L")}
                      )
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function BankAccount() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const fetch_bank_accounts = () => {
    setOpen(true);
    Axios.get("api/estate/bank/" + client_case_id)
      .then((res) => {
        let banks = res.data.data;
        banks.sort((a, b) =>
          a.institution_name > b.institution_name ? 1 : -1
        );
        setBanks(banks);
        setOpen(false);
      })
      .catch((err) => {
        console.log("error", err);
        setOpen(false);
      });
  };
  const openAlert = ()=> {
    setShowAlert(true);

}

  const closeAlert = ()=> {
    setShowAlert(false); 
  }
  
  const handleAutoUpdatePlaid = async () => {
    setLoading(true);
    
    //Filter bank accounts to plaid accounts only.
    const plaid_accounts = banks.filter((bank) => bank.isPlaid);
    
    //return the function if no plaid accounts connected.
    if(!plaid_accounts.length) {
      setLoading(false);
      setErrMessage('Bank is not found');
      openAlert();
      return;
    }
    
    //get plaid connected accounts institutions.
    const plaid_institutions = await Axios.get(`api/estate/bank/account/institutions/${client_case_id}`)
      .then(res => {return res.data})
      .catch((e)=> { 
        setLoading(false);
      });
    
    //check for balances for plaid accounts.
    const updated_accounts = await Promise.all(plaid_accounts.map(async (account) => {
      const institution_lookup = plaid_institutions.find((institution) => institution.institunional_id === account.institutional_id);
      const institution_access_token = institution_lookup?.access_token;
      
      if(!institution_access_token) return ;
      
      //fetch balance for account.
      const updated_balance = await Axios.post(process.env.REACT_APP_NODE_URL + "/balance", {access_token: institution_access_token, options:{account_ids: [account.plaid_account_id]}})
          .then(res => {
            return res.data.accounts[0].balances.available
          })
          .catch((e)=>{ 
            setLoading(false);
          });
      return {
        id: account.id,
        account_name: account.account_name,
        type: account.type,
        balance: updated_balance,
        account_number: account.account_number,
        institution_name: account.institution_name,
        notes: account.notes,
        client_case_id: client_case_id,
        balance_date: new Date().toLocaleDateString('en-CA'),
        isPlaid: true,
        institutional_id: account.institutional_id
      }
    }));
    
    
    var insert = await Axios.put(
      "api/estate/bank/account/insert/update",
      updated_accounts
    ).catch((e)=>{  
      setLoading(false);
    });
    
    if (insert) {
      setBanks(insert.data.data);
    }
    
    setLoading(false);
    
  };

  useEffect(() => {
    fetch_bank_accounts();
  }, []);

  return (
    <>
      <ClientWiseSpinner show={loading} />
      <ClientWiseAlert showAlert={showAlert} closeAlert={closeAlert} message={errMessage} status={'danger'}/>
      <div id="real-property" className={`card h-100 card-custom `}>
        <div className="card-header border-0 mt-3">
          <h3 className="card-title align-items-start flex-column align-self-center">
            <span className="card-label font-weight-bolder text-dark">
              Bank Accounts
            </span>
            <span className="text-muted mt-0 font-weight-bold font-size-sm">
              Checking, savings, & other deposit accounts
            </span>
          </h3>
        </div>
        <hr />
        <div className="card-title mb-0">
          <div className="d-flex px-9 align-items-center">
            <h4>Bank Accounts</h4>
            <div className="ml-auto">
              <CreateBankAccount
                client_case_id={client_case_id}
                setBanks={setBanks}
              />
            </div>
            {/* <div className="ml-3">
              <PlaidLinkComponent
                client_case_id={client_case_id}
                setBanks={setBanks}
              />
            </div> */}
            <div className="ml-6">
              <button
                className="btn btn-primary"
                onClick={handleAutoUpdatePlaid}
              >
                Update Linked Accounts
              </button>
            </div>
          </div>
        </div>
        <div className="pb-10 pl-10 pr-10 pt-0">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                    Description
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Value
                  </TableCell>
                  {/* <TableCell align="left" className="font-weight-bold text-uppercase text-muted fs-1">Debt</TableCell> */}
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Client SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Spouse SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Net Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banks.map((row) => (
                  <BankAccountRow
                    key={row.id}
                    row={row}
                    setBanks={setBanks}
                    client_case_id={client_case_id}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {open && <Loading />}
    </>
  );
}
export default BankAccount;
