import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RealPropertyRow from './RealProperty/RealProperty';
import MineralInterestRow from './MineralInterest/MineralInterest';
import EquitableClaimSeparateRow from './EquitableClaimSeparate/EquitableClaimSeparate'
import EquitableClaimMaritalRow from './EquitableClaimMarital/EquitableClaimMarital';
import AssetHeldBenefitRow from './AssetHeldBenefit/AssetHeldBenefit';
import AssetHeldAnotherRow from './AssetHeldAnother/AssetHeldAnother';
import ChildrenRow from './Children/Children';
import OtherLiabilitiesRow from './OtherLiabilities/OtherLiabilities';
import ContingentLiabilitiesRow from './ContingentLiabilities/ContingentLiabilities';
import AmountsOwedRow from './AmountsOwed/AmountsOwed';
import TaxRow from './TaxLiabilities/Tax';
import CreditCardRow from './CreditCard/CreditCard';
import OtherAssetsRow from './OtherAssets/OtherAssets';
import ContingentRow from './Contingent/Contingent';
import StorageFacilitiesRow from './StorageFacilities/StorageFacilities';
import SafeDepositRow from './SafeDeposit/SafeDeposit';
import IntellectualPropertyRow from './IntellectualProperty/IntellectualProperty';
import TravelAwardRow from './TravelAward/TravelAward';
import ClubMembershipRow from './ClubMemberships/ClubMemberships';
import LiveStockRow from './LiveStock/LiveStock';
import PetsRow from './Pets/Pets';
import BothPossessionRow from './BothPossession/BothPossession';
import SpousePossessionRow from './SpousePossession/SpousePossession';
import ClientPossessionRow from './ClientPossession/ClientPossession';
import MoneyOwedRow from './MoneyOwed/MoneyOwed';
import VehicleRow from './Vehicle/Vehicle';
import AnnuitiesRow from './Annuities/Annuities';
import LifeInsuranceRow from './LifeInsurance/LifeInsurance';
import UnionRow from './Union/Union';
import CompensationRow from './Compensation/Compensation';
import GovernmentRow from './Government/Government';
import NonQualifiedRow from './NonQualified/NonQualified';
import MilitaryRow from './Military/Military';
import IRASEPRow from './IRASEP/IRASEP';
import BenefitRow from './Benefit/Benefit';
import ContributionRow from './Contribution/Contribution';
import BusinessInterestRow from './BusinessInterest/BusinessInterest';
import BonusesRow from './Bonuses/Bonuses';
import EmployeeStockRow from './EmployeeStock/EmployeeStock';
import StockOptionRow from './StockOption/StockOption';
import PublicStockRow from './PublicStock/PublickStock';
import BrokerageRow from './Brokerage/Brokerage';
import BankAccountsRow from './BankAccounts/BankAccounts';
import EquivalentsRow from './Equivalents/Equivalents';
import CashRow from './Cash/Cash';
import PreciousMetalsRow from './PreciousMetals/PreciousMetals';
import { EstateContext } from '../../../../../hooks/ContextStore';
import { EncryptStorage } from 'encrypt-storage';

function EstateSpreadsheetLiabilities(props){
    const [open, setOpen]                  = useState(false);
    const mobile                           = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]          = useState(mobile.matches);
    const [category, setCategory]          = useContext(EstateContext);
    const data                             = category != undefined ? category : null;
    const encryptStorage                   = new EncryptStorage("secret-key");
    
    const initialVal = () => {
        return {
          client_case_id: data ? data.client_case_id : false,
          real_property: data ? Boolean(data.real_property) : false,
          mineral_interest: data ? Boolean(data.mineral_interest) : false,
          cash: data ? Boolean(data.cash) : false,
          equivalents: data ? Boolean(data.equivalents) : false,
          bank_account: data ? Boolean(data.bank_account) : false,
          brokerage: data ? Boolean(data.brokerage) : false,
          public_stocks: data ? Boolean(data.public_stocks) : false,
          employee_stock_option: data ? Boolean(data.employee_stock_option) : false,
          employee_stock: data ? Boolean(data.employee_stock) : false,
          bonuses: data ? Boolean(data.bonuses) : false,
          business_interest: data ? Boolean(data.business_interest) : false,
          contribution_plan: data ? Boolean(data.contribution_plan) : false,
          benefit_plan: data ? Boolean(data.benefit_plan) : false,
          ira_sep: data ? Boolean(data.ira_sep) : false,
          military_benefit: data ? Boolean(data.military_benefit) : false,
          non_qualified_plan: data ? Boolean(data.non_qualified_plan) : false,
          government_benefits: data ? Boolean(data.government_benefits) : false,
          compensation_benefits: data ? Boolean(data.compensation_benefits) : false,
          union_benefits: data ? Boolean(data.union_benefits) : false,
          life_insurance: data ? Boolean(data.life_insurance) : false,
          annuities: data ? Boolean(data.annuities) : false,
          vehicles: data ? Boolean(data.vehicles) : false,
          money_owned: data ? Boolean(data.money_owned) : false,
          client_possession: data ? Boolean(data.client_possession) : false,
          spouse_possession: data ? Boolean(data.spouse_possession) : false,
          spouse_both: data ? Boolean(data.spouse_both) : false,
          pets: data ? Boolean(data.pets) : false,
          livestock: data ? Boolean(data.livestock) : false,
          club_membership: data ? Boolean(data.club_membership) : false,
          travel_award_benefit: data ? Boolean(data.travel_award_benefit) : false,
          intellectual_property: data ? Boolean(data.intellectual_property) : false,
          precious_metals: data ? Boolean(data.precious_metals) : false,
          safe_deposit: data ? Boolean(data.safe_deposit) : false,
          contingent_assets: data ? Boolean(data.contingent_assets) : false,
          other_assets: data ? Boolean(data.other_assets) : false,
          storage_facilities: data ? Boolean(data.storage_facilities) : false,
          credit_card: data ? Boolean(data.credit_card) : false,
          federal_liabilities: data ? Boolean(data.federal_liabilities) : false,
          contingent_liabilities: data
            ? Boolean(data.contingent_liabilities)
            : false,
          other_liabilities: data ? Boolean(data.other_liabilities) : false,
          children_assets: data ? Boolean(data.children_assets) : false,
          asset_held_for_benefit: data
            ? Boolean(data.asset_held_for_benefit)
            : false,
          asset_held_for_either_party: data
            ? Boolean(data.asset_held_for_either_party)
            : false,
          equitable_for_community_estate: data
            ? Boolean(data.equitable_for_community_estate)
            : false,
          equitable_for_separate_estate: data
            ? Boolean(data.equitable_for_separate_estate)
            : false,
          amount_owned_to_attorney_or_professionals: data
            ? Boolean(data.amount_owned_to_attorney_or_professionals)
            : false,
        };
    };

    const {
        updateRealProperty,
        updateMineralInterest,
        updateEstateIntellectualProperty,
        updateEstateOtherAsset,
        updateCash,
        updateFinancialInstitution,
        updateBrokerage,
        updatePublicStock,
        updateStockOption,
        updateEmployeeStock,
        updateBonus,
        updateCloseslyHeldBusiness,
        updateContribution,
        updateBenefitPlan,
        updateIRASEP,
        updateMilitaryBenefits,
        updateNonQualifiedPlan,
        updateGovernmentBenefit,
        updateOtherDeferedCompensation,
        updateUnionBenefit,
        updateLifeInsurance,
        updateAnnuity,
        updateVehicle,
        updateMoneyOwed,
        updateAsset,
        updateClubMembership,
        updateTravelAward,
        updateSafeDepositBox,
        updateStorageFacility,
        updateContingentAsset,
        updateCreditCard,
        updateTaxLiability,
        updateAmountOwed,
        updateContingentLiability,
        updateOtherLiability,
        updateChildAsset,
        updateEquitableClaim,
        updatePreciousMetals,
        updateEstatePropertiesInTrust
    }                                      = props;

    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };

        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }

    }, []);

    const onEstateCategoriesUpdated = () => {
        setCategory(encryptStorage.getItem('EstateCategories'));
      };
      
    useEffect(() => {
        window.addEventListener("storage", onEstateCategoriesUpdated)
        return () => {
            window.removeEventListener("storage", onEstateCategoriesUpdated);
        };
    },[]);
      
    return(
        <>
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                <TableCell className="text-uppercase text-muted">DESCRIPTION</TableCell>
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">Value</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">DEBT</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">Client Debt</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">Spouse Debt</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">NET VALUE</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO CLIENT</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">TO SPOUSE</TableCell> : null }
                {!isMobile ? <TableCell align="left" className="text-uppercase text-muted">%</TableCell> : null }
                <TableCell align="left" className="text-uppercase text-muted">ACTION</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* Credit Cards & Revolving Credit */}
                    {initialVal().credit_card ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Credit Cards & Revolving Credit</TableCell>
                </TableRow>
                    :''}

                    {initialVal().credit_card ? props.division && props.division.credit_card.map((row) => (
                        <CreditCardRow key={row.id} row={row} updateContingentAsset={updateCreditCard}/>
                    )): ''}
                {/* Credit Cards & Revolving Credit*/}


                {/* Federal, State, and Local Tax Liabilities */}
                    {initialVal().federal_liabilities ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Federal, State, and Local Tax Liabilities</TableCell>
                    </TableRow>
                    : ''}

                    {initialVal().federal_liabilities ? props.division && props.division.tax_liabilities.map((row) => (
                        <TaxRow key={row.id} row={row} updateTaxLiability={updateTaxLiability} />
                    )): ''}
                {/* Federal, State, and Local Tax Liabilities */}


                {/* Amounts Owed to Attorneys and/or Professionals in this Case */}
                    {initialVal().amount_owned_to_attorney_or_professionals ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Amounts Owed to Attorneys and/or Professionals in this Case</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().amount_owned_to_attorney_or_professionals ? props.division && props.division.amount_owed.map((row) => (
                        <AmountsOwedRow key={row.desc} row={row} updateMoneyOwed={updateAmountOwed}/>
                    )): ''}
                {/* Amounts Owed to Attorneys and/or Professionals in this Case */}

                {/* Contingent Liabilities */}
                    {initialVal().contingent_liabilities ?
                    <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Contingent Liabilities</TableCell>
                    </TableRow>
                    :''}

                    {initialVal().contingent_liabilities ? props.division && props.division.contigent_liabilities.map((row) => (
                        <ContingentLiabilitiesRow key={row.id} row={row} updateContingentLiability={updateContingentLiability} />
                    )): ''}
                {/* Contingent Liabilities */}

                {/* Other Liabilities (not described elsewhere) */}
                {initialVal().other_liabilities  ?
                <TableRow>
                    <TableCell colSpan={10} className="text-uppercase" style={{backgroundColor: "#EBEDF3"}}>Other Liabilities (not described elsewhere)</TableCell>
                </TableRow> : ''}


                {initialVal().other_liabilities  ? props.division && props.division.other_liabilities.map((row) => (
                    <OtherLiabilitiesRow key={row.id} row={row} updateOtherLiability={updateOtherLiability}/>
                )): ''}
                {/* Other Liabilities (not described elsewhere) */}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default EstateSpreadsheetLiabilities