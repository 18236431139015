import React, { useEffect, useState, useContext } from "react";
import {  Route, Switch } from "react-router-dom";
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import DocumentFolderList from "./_partials/DocumentFolderList/DocumentFolderList";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from "@material-ui/core";
import FolderListComponent from "./components/FolderListComponent";

async function asyncForEach(array, callback){
    for (let index = 0; index < array.length; index++){
        await callback(array[index], index, array);
    }
}

function DocumentPage(){
    const encryptStorage                = new EncryptStorage("secret-key");
    const decypt_data                   = encryptStorage.getItem("case_details");
    const data                          = decypt_data;
    const client_case_id                = data ? data.case.id : null;
    const defaultFolders                = [];
    const [routes, setRoutes]           = useState([]);
    const [notif, showNotif]            = useState(false);
    const [notifMsg, setNotifMsg]       = useState("File Downloading");
    
    const defaultRoutes = [
        //Default Parent
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Estate',
        //     section_id: 'estate-default',
        //     isParent:1,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Children',
        //     section_id: 'children-default',
        //     isParent:1,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Timeline',
        //     section_id: 'timeline-default',
        //     isParent:1,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        {
            id:0,
            client_case_id:client_case_id,
            section_name:'My Documents',
            section_id: 'mydocuments',
            isParent:1,
            Parent:0,
            Type:'Folder',
            created_by: 'Default',
        },
        //Default Estate
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Real Property',
        //     section_id: 'real-property',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Mineral Interest',
        //     section_id: 'mineral-interest',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Cash',
        //     section_id: 'cash',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Equivalents',
        //     section_id: 'equivalents',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Bank Account',
        //     section_id: 'bank-account',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'brokerage',
        //     section_id: 'Brokerage',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Public Stocks',
        //     section_id: 'public-stocks',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Employee Stock Option',
        //     section_id: 'employee-stock-option',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Employee Stock',
        //     section_id: 'employee-stock',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'bonuses',
        //     section_id: 'Bonuses',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Business Interest',
        //     section_id: 'business-interest',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Contribution Plan',
        //     section_id: 'contribution-plan',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Benefit Plan',
        //     section_id: 'benefit-plan',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Ira-Sep',
        //     section_id: 'ira-sep',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Military Benefit',
        //     section_id: 'military-benefit',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Non-Qualified Plan',
        //     section_id: 'non-qualified-plan',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Government Benefits',
        //     section_id: 'government-benefits',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Compensation Benefits',
        //     section_id: 'compensation-benefits',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Union Benefits',
        //     section_id: 'union-benefits',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Life Insurance',
        //     section_id: 'life-insurance',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Annuities',
        //     section_id: 'annuities',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Vehicle',
        //     section_id: 'vehicle',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Client Possession',
        //     section_id: 'client-possession',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Money Owned',
        //     section_id: 'money-owned',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Spouse Possession',
        //     section_id: 'spouse-possession',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Both Possession',
        //     section_id: 'spouse-both',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Pets',
        //     section_id: 'pets',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Livestock',
        //     section_id: 'livestock',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Club Membership',
        //     section_id: 'club-membership',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Travel Award Benefit',
        //     section_id: 'travel-award-benefit',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Intellectual Property',
        //     section_id: 'intellectual-property',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Safe Deposit',
        //     section_id: 'safe-deposit',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Storage Facilities',
        //     section_id: 'storage-facilities',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Contingent Assets',
        //     section_id: 'contingent-assets',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Other Assets',
        //     section_id: 'other-assets',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Credit Card',
        //     section_id: 'credit-card',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Federal Liabilities',
        //     section_id: 'federal-liabilities',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Contingent Liabilities',
        //     section_id: 'contingent-liabilities',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Other Liabilities',
        //     section_id: 'other-liabilities',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Amount Owbned to Attorney or Professionals',
        //     section_id: 'amount-owned-to-attorney-or-professionals',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Children Assets',
        //     section_id: 'children-assets',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Asset Held for Either Party',
        //     section_id: 'asset-held-for-either-party',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Asset Held for Either Party',
        //     section_id: 'asset-held-for-either-party',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Asset Held for Benefit',
        //     section_id: 'asset-held-for-benefit',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Equitable For Community Estate',
        //     section_id: 'equitable-for-community-estate',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Equitable For Separate Estate',
        //     section_id: 'equitable-for-separate-estate',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // //Default Children
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Activities',
        //     section_id: 'children-activities-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Living Environment',
        //     section_id: 'living-environment-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Work Schedule',
        //     section_id: 'work-schedule-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Special Needs',
        //     section_id: 'special-needs-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Difficult Issues',
        //     section_id: 'difficult-issues-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // },
        // {
        //     id:0,
        //     client_case_id:client_case_id,
        //     section_name:'Other Relevant',
        //     section_id: 'other-relevant-default',
        //     isParent:0,
        //     Parent:0,
        //     Type:'Folder',
        //     created_by: 'Default',
        // }
    ];

    let dataProps = {
        client_case_id:client_case_id,
        allFolders:routes,
        defaultFolders:defaultRoutes,
        setRoutes:setRoutes,
        showNotif:showNotif,
        setNotifMsg:setNotifMsg,
    }

    const handleCloseNotif = () => {
        showNotif(false);
    }

    const getCustomFolders = async()=>{
        /**
         * Fetch all Custom Sections for Route
         * @param client_case_id
         */
        await Axios.get(`api/documents/section/list/${client_case_id}`)
        .then(async(res) => {
            /**
             * Make Default Routes as Default Folders
             * @param defaultFolders
             */
            await asyncForEach(defaultRoutes, async(folder) => {
                defaultFolders.push(folder);
            });
            /**
             * Make Custom Folders as Default Folders
             * @param res.data.data
             */
            await asyncForEach(res.data.data, async(file) =>{
                defaultFolders.push(file)
            });
            setRoutes(defaultFolders);
        })
        .catch((e)=>{
            console.log(e);
        })
    }

    useEffect(()=>{
        getCustomFolders();
    }, []);

    return(
        <div id="documentsPage" className="row" style={{ gap: '20px'}}>

            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="d-flex flex-column">
                                <h3 className="m-0 text-dark font-weight-bolder">Documents</h3>
                                <span class="text-muted mt-0 font-weight-bold font-size-sm">
                                    Case Related Documents
                                </span>
                            </div>
                            {/* <div className="ml-auto">
                                <Button variant="contained" color="secondary">Add New Folder</Button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <Switch>
                            {
                            routes.map((route, index) => (
                                <Route 
                                path={'/documents/'+route.section_id}
                                render={(props) => (
                                    <DocumentFolderList props={dataProps} folder={route}/>
                                )}
                                />
                            ))
                            }
                        </Switch>
                        {/* <FolderListComponent client_case_id={client_case_id}/> */}
                    </div>
                </div>
            </div>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                open={notif}
                onClose={handleCloseNotif}
                message={notifMsg}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseNotif}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
        </div>
    )
    

}
export default DocumentPage