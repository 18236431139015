import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { EstateContext } from "../../../hooks/ContextStore";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { connect } from "react-redux";

function EstateCard() {
  const [category, setCategory] = useContext(EstateContext);
  const data = category != undefined ? category : null;
  const ctgry = data ? true : false;

  let real_property,
    cash_equivalent,
    bank_account,
    brokerage,
    stocks,
    bonus,
    business_interest,
    retirement_benefit,
    life_insurance,
    vehicles,
    money_owned,
    personal_property,
    livestock,
    club_membership,
    travel_benefits,
    other_type_of_assets,
    community_liabilities,
    children_assets,
    property_held_in_trust,
    equitable_claims;

  if (ctgry) {
    if (
      Boolean(data.real_property) === true ||
      Boolean(data.mineral_interest) === true
    ) {
      real_property = (
        <NavLink
          to="/estate/real-estate/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
            ></SVG>
            Real Property
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.cash) === true || Boolean(data.equivalents) === true) {
      cash_equivalent = (
        <NavLink
          to="/estate/cash-onhand/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
            ></SVG>
            Cash and Equivalents
          </span>
        </NavLink>
      );
    }

    if (Boolean(data.bank_account) === true) {
      bank_account = (
        <NavLink
          // onClick={()=>{
          //   get_plaid_link_token().then(res=>{
          //     localStorage.setItem('plaid_token',res.data.link_token);
          //   })
          // }}
          to="/estate/bank-accounts/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Safe.svg")}
            ></SVG>
            Bank Accounts
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.brokerage) === true) {
      brokerage = (
        <NavLink
          to="/estate/brokerage/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}
            ></SVG>
            Brokerage
          </span>
        </NavLink>
      );
    }
    if (
      Boolean(data.public_stocks) === true ||
      Boolean(data.employee_stock_option) === true ||
      Boolean(data.employee_stock) === true
    ) {
      stocks = (
        <NavLink
          to="/estate/stocks/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Thumbtack.svg"
              )}
            ></SVG>
            Stocks
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.bonuses) === true) {
      bonus = (
        <NavLink
          to="/estate/bonuses/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
            ></SVG>
            Bonuses
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.business_interest) === true) {
      business_interest = (
        <NavLink
          to="/estate/business-interests/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")}
            ></SVG>
            Closely Held Business Interest
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.contribution_plan) === true ||
      Boolean(data.benefit_plan) === true ||
      Boolean(data.ira_sep) === true ||
      Boolean(data.military_benefit) === true ||
      Boolean(data.non_qualified_plan) === true ||
      Boolean(data.government_benefits) === true ||
      Boolean(data.compensation_benefits) === true ||
      Boolean(data.union_benefits) === true
    ) {
      retirement_benefit = (
        <NavLink
          to="/estate/retirement-benefits/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort3.svg")}
            ></SVG>
            Retirement Benefits
          </span>
        </NavLink>
      );
    }
    if (
      Boolean(data.life_insurance) === true ||
      Boolean(data.annuities) === true
    ) {
      life_insurance = (
        <NavLink
          to="/estate/insurance-annuities/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Weather/Umbrella.svg")}
            ></SVG>
            Life Insurance and Annuities
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.intellectual_property) === true ||
      Boolean(data.safe_deposit) === true ||
      Boolean(data.storage_facilities) === true ||
      Boolean(data.contingent_assets) === true ||
      Boolean(data.other_assets) === true ||
      Boolean(data.precious_metals) === true
    ) {
      other_type_of_assets = (
        <NavLink
          to="/estate/miscellaneous-property/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
            ></SVG>
            Other Types of Assets
          </span>
        </NavLink>
      );
    }

    if (Boolean(data.vehicles) === true) {
      vehicles = (
        <NavLink
          to="/estate/vehicles/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
            ></SVG>
            Motor Vehicles, Airplane, Cycles, Etc.
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.money_owned) === true) {
      money_owned = (
        <NavLink
          to="/estate/money-owed/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet3.svg")}
            ></SVG>
            Money Owed to Me or My Spouse
          </span>
        </NavLink>
      );
    }
    if (
      Boolean(data.client_possession) === true ||
      Boolean(data.spouse_possession) === true ||
      Boolean(data.spouse_both) === true
    ) {
      personal_property = (
        <NavLink
          to="/estate/personal-property/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Files/User-folder.svg")}
            ></SVG>
            Personal Property
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.pets) === true || Boolean(data.livestock) === true) {
      livestock = (
        <NavLink
          to="/estate/livestock/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Food/Chicken.svg")}
            ></SVG>
            Pets & Livestock
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.club_membership) === true) {
      club_membership = (
        <NavLink
          to="/estate/club-memberships/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
            ></SVG>
            Club Memberships
          </span>
        </NavLink>
      );
    }
    if (Boolean(data.travel_award_benefit) === true) {
      travel_benefits = (
        <NavLink
          to="/estate/travel/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
            ></SVG>
            Travel and Award Benefits
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.intellectual_property) === true ||
      Boolean(data.safe_deposit) === true ||
      Boolean(data.storage_facilities) === true ||
      Boolean(data.contingent_assets) === true ||
      Boolean(data.other_assets) === true
    ) {
      other_type_of_assets = (
        <NavLink
          to="/estate/miscellaneous-property/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")}
            ></SVG>
            Other Types of Assets
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.credit_card) === true ||
      Boolean(data.federal_liabilities) === true ||
      Boolean(data.contingent_liabilities) === true ||
      Boolean(data.amount_owned_to_attorney_or_professionals) === true ||
      Boolean(data.other_liabilities) === true
    ) {
      community_liabilities = (
        <NavLink
          to="/estate/community/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}
            ></SVG>
            Debts & Other Liabilities
          </span>
        </NavLink>
      );
    }

    if (Boolean(data.children_assets) === true) {
      children_assets = (
        <NavLink
          to="/estate/child-accounts/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            ></SVG>
            Children’s Assets
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.asset_held_for_either_party) === true ||
      Boolean(data.asset_held_for_benefit) === true
    ) {
      property_held_in_trust = (
        <NavLink
          to="/estate/property-trust/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")}
            ></SVG>
            Property Held in Trust
          </span>
        </NavLink>
      );
    }

    if (
      Boolean(data.equitable_for_community_estate) === true ||
      Boolean(data.equitable_for_separate_estate) === true
    ) {
      equitable_claims = (
        <NavLink
          to="/estate/equitable-claims/"
          className="navi-link py-4"
          activeClassName="active"
        >
          <span className="navi-text font-size-lg svg-icon svg-icon-md svg-icon-green align-items-center">
            <SVG
              className="mr-1"
              src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")}
            ></SVG>
            Equitable Claims
          </span>
        </NavLink>
      );
    }
  } 

  return (
    <div
      className="flex-row-auto offcanvas-mobile col-3 pl-0"
      id="kt_profile_aside"
    >
      <div className="card card-custom card-stretch">
        <div className="card-body pt-4">
          <div className="d-flex justify-content-start my-5">
            <h2>Estate</h2>
          </div>
          <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
            <div className="navi-item mb-2">{real_property}</div>
            <div className="navi-item mb-2">{cash_equivalent}</div>
            <div className="navi-item mb-2">{bank_account}</div>
            <div className="navi-item mb-2">{brokerage}</div>
            <div className="navi-item mb-2">{stocks}</div>
            <div className="navi-item mb-2">{bonus}</div>
            <div className="navi-item mb-2">{business_interest}</div>
            <div className="navi-item mb-2">{retirement_benefit}</div>
            <div className="navi-item mb-2">{life_insurance}</div>
            <div className="navi-item mb-2">{vehicles}</div>
            <div className="navi-item mb-2">{money_owned}</div>
            <div className="navi-item mb-2">{personal_property}</div>
            <div className="navi-item mb-2">{livestock}</div>
            <div className="navi-item mb-2">{club_membership}</div>
            <div className="navi-item mb-2">{travel_benefits}</div>
            <div className="navi-item mb-2">{other_type_of_assets}</div>
            <div className="navi-item mb-2">{community_liabilities}</div>
            <div className="navi-item mb-2">{children_assets}</div>
            <div className="navi-item mb-2">{property_held_in_trust}</div>
            <div className="navi-item mb-2">{equitable_claims}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (auth) => ({
  data: auth,
});

export default connect(mapStateToProps)(EstateCard);
