import MomentUtils from "@date-io/moment";
import { Dialog, DialogContent, DialogTitle, Divider, NativeSelect } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import { useFormik } from "formik";
import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    //   border: '1px solid #ced4da',
    fontSize: 16,
    //   padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: '#80bdff',
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function AddChildrenInvolve({
  open,
  setLoading,
  setOpen,
  setChildren,
  children,
}) {
  const encryptStorage = new EncryptStorage("secret-key");
  const [client_case_id, setClientCaseId] = useState(
    encryptStorage.getItem("client_case_id")
  );
  const [gender, setGender] = useState('female');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date.toString());
  };

  const handleChange = (event) => {
    setGender(event.target.value);
    // setAge(event.target.value);
  };
  
  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const onSubmit = async (values) => {
   

    const data = {
      last_four_ss: values.last_four_ss,
      gender: gender,
      firstname: values.firstname,
      lastname: values.lastname,
      client_case_id: values.client_case_id,
      birthday:selectedDate,
    };

    setLoading(true);
    axios
      .post("api/children/involve", data)
      .then((response) =>{
        setOpen(false);
        setLoading(false);
        formik.resetForm();
        setChildren(children.concat(response.data.children));
      })
      .catch((err) => {
        if (err && err.response) console.log("Error", err);
      });
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    gender: "female",
    last_four_ss: "",
    birthday: "",
    client_case_id: client_case_id ? client_case_id : null,
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnMount: true,
    initialValues,
    onSubmit,
  });

  const debouncedFormik = useMemo(() => debounce(formik.validateForm, 1500), [
    formik.validateForm,
  ]);
  useEffect(() => {
    debouncedFormik(formik.values);
  }, [formik.values, debouncedFormik]);

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="mtb-10">
        {"Children involved in this case (if any):"}
      </DialogTitle>
      <Divider className="mb-10" />
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
            <Form.Label>First & Middle Name:</Form.Label>
            <input
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 `}
              name="firstname"
              onChange={formik.handleChange}
              value={formik.values.firstname}
            />
            <div className="fv-plugins-message-container">
              {/* <div className="fv-help-block">
                      {formik.errors.firstname ? formik.errors.firstname : ""}
                    </div> */}
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <Form.Label>Last Name</Form.Label>
            <input
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 `}
              name="lastname"
              onChange={formik.handleChange}
              value={formik.values.lastname}
            />
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.lastname ? formik.errors.lastname : ""}
              </div>
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <Form.Label>Last Three SS #</Form.Label>
            <input
              type="number"
              className={`form-control form-control-solid h-auto py-5 px-6 `}
              name="last_four_ss"
              onChange={formik.handleChange}
              value={formik.values.last_four_ss}
            />

            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.last_four_ss ? formik.errors.last_four_ss : ""}
              </div>
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <Form.Label>Gender</Form.Label>

            <NativeSelect
              id="demo-customized-select-native"
              name="gender"
              className={`form-control h-auto  `}
              value={gender}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="other">Other</option>
              <option value="Prefer not to say">Prefer Not To Say</option>
            </NativeSelect>
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.gender ? formik.errors.gender : ""}
              </div>
            </div>
          </div>

          <div className="form-group fv-plugins-icon-container">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                margin="normal"
                id="date-picker-inline"
                label="Date of Birth"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <Divider className="form-bottom-divider" />

          <div className="form-group d-flex flex-wrap align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-success font-weight-bold px-9 py-4 my-3`}
            >
              <span>Submit</span>
              {/* {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )} */}
            </button>
            <a
              onClick={handleClose}
              className={`btn btn-light text-dark font-weight-bold px-9 py-4 my-3 ml-3`}
            >
              <span>Cancel</span>
            </a>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
