import React,{useState} from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    IconButton,
    TextField,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import EditIcon from '@material-ui/icons/Edit';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import { useFormik } from 'formik';
import Axios from 'axios';
import * as yup from "yup";

const validationSchema = yup.object({
  value: yup.number().test(
    "maxDigits",
    "Value field must be 10 digits or less",
    (value) => String(value).length <= 10
  )
  .required("Value is required!"),


  client_sp: yup.number().test(
    "maxDigits",
    "Client’s separate propertyfield must be 10 digits or less",
    (client_sp) => String(client_sp).length <= 10
  )
  .required("Client’s separate property is required!"),

  spouse_sp: yup.number().test(
    "maxDigits",
    "Spouse’s separate property field must be 10 digits or less",
    (spouse_sp) => String(spouse_sp).length <= 10
  )
  .required("Spouse’s separate property is required!"),

  cash_surrender: yup.number().test(
    "maxDigits",
    "Cash surrender field must be 10 digits or less",
    (cash_surrender) => String(cash_surrender).length <= 10
  )
  .required("Cash surrender is required!"),

  distributed: yup.number().test(
    "maxDigits",
    "Amount to be distributed  field must be 10 digits or less",
    (distributed) => String(distributed).length <= 10
  )
  .required("Amount to be distributed  is required!"),

  // policy: yup.number().notRequired().nullable().test(
  //   "maxDigits",
  //   "Invalid Policy number",
  // function(value){
  //   if(value === undefined || value ===null ){
  //     return true;
  //   }
  //   else if(String(value).length === 4){
  //     return  true;
  //     }
  //     else{
  //       return  false;
  //     }
  // }),
});

export default function Edit(props){
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    const {row,client_case_id,setInsurances} = props;
    const [valueDate, setValueDate] =useState(new Date(row.value_date));
    const [payDate, setPayDate] =useState(new Date(row.annuitization));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

 const handleDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setValueDate(new_date);
    };

    const handlePayDate = (date) => {
      let date_dt = new Date(date);
      let new_date=  new Date(date_dt.getFullYear(), date_dt.getMonth(),date_dt.getDate(),'10', '56', '00')
      setPayDate(new_date);
    };

    const onSubmit = async (values) => {
      setLoading(true);
      const formObj ={
          client_case_id: client_case_id ? client_case_id : null,
          name:values.name,
          distribute_type:values.distribute_type,
          policy:values.policy,
          annuitant:values.annuitant,
          annuitization:payDate,
          distributed:values.distributed,
          period:values.period,
          cash_surrender:values.cash_surrender,
          beneficiary:values.beneficiary,
          death_benefit:values.death_benefit,
          value:values.value,
          value_date:valueDate,
          other_liens:values.other_liens,
          client_sp:values.client_sp,
          spouse_sp:values.spouse_sp,
          to_client:values.to_client,
          cost:values.cost,
          notes:values.notes,
      };

      const response = await Axios
        .put("api/estate/annuities/"+row.id, formObj)
        .catch((err) => {
          if (err && err.response) console.log("Error", err);
        });
  
      if (response) {
        setOpen(false);
        setInsurances(response.data.data);
        setTimeout(()=>{
          setLoading(false);
        }, 1500);
      }
    };

    const formik = useFormik({
      initialValues: {
        name:row.name,
        policy:row.policy,
        annuitant:row.annuitant,
        annuitization:row.annuitization,
        distributed:row.distributed,
        period:row.period,
        cash_surrender:row.cash_surrender,
        beneficiary:row.beneficiary,
        death_benefit:row.death_benefit,
        value:row.value,
        other_liens:row.other_liens,
        client_sp:row.client_sp,
        spouse_sp:row.spouse_sp,
        to_client:row.to_client,
        cost:row.cost,
        notes:row.notes,
        distribute_type:row.distribute_type,
      },
      validateOnBlur: true,
      onSubmit,
      validationSchema:validationSchema
    });

    return (
        <div>
          <div className="card-toolbar"> 
                <IconButton aria-label="update" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton> 
            </div>
          <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Annuities"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                <div className="login-form login-signin" id="kt_login_signin_form">
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                  >
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of company</Form.Label>
                      <input
                        type="text"
                        name="name"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.name ? formik.errors.name : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Policy number (last 4 digits)</Form.Label>
                      <input
                        type="text"
                        name="policy"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.policy}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.policy ? formik.errors.policy : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Name of annuitant</Form.Label>
                      <input
                        type="text"
                        name="annuitant"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.annuitant}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.annuitant ? formik.errors.annuitant : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Type of distribution </Form.Label>
                      <input
                        type="text"
                        name="distribute_type"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.distribute_type}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.distribute_type ? formik.errors.distribute_type : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Amount to be distributed per period</Form.Label>
                      <input
                        type="number"
                        name="distributed"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.distributed}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.distributed ? formik.errors.distributed : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Dates payments began or will begin (Date of annuitization)</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}  utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={payDate}
                              onChange={handlePayDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Cash surrender value</Form.Label>
                      <input
                        type="number"
                        name="cash_surrender"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.cash_surrender}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.cash_surrender ? formik.errors.cash_surrender : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Designated beneficiary</Form.Label>
                      <input
                        type="text"
                        name="beneficiary"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.beneficiary}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.beneficiary ? formik.errors.beneficiary : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Current value as of</Form.Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}  utils={MomentUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/DD/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={valueDate}
                              onChange={handleDate}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                            />
                      </MuiPickersUtilsProvider>
                      <Form.Label>Current value</Form.Label>
                      <input
                        type="number"
                        name="value"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.value}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.value ? formik.errors.value : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Client’s separate property</Form.Label>
                      <input
                        type="number"
                        name="client_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.client_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.client_sp ? formik.errors.client_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Spouse’s separate property</Form.Label>
                      <input
                        type="number"
                        name="spouse_sp"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.spouse_sp}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.spouse_sp ? formik.errors.spouse_sp : ""}
                        </div>
                      </div>
                    </div>

                    <div className="form-group fv-plugins-icon-container">
                      <Form.Label>Notes</Form.Label>
                      <input
                        type="text"
                        name="notes"
                        className={`form-control form-control-solid h-auto py-5 px-6 `}
                        onChange={formik.handleChange}
                        onblur={formik.handleBlur}
                        value={formik.values.notes}
                      />
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                        {formik.errors.notes ? formik.errors.notes : ""}
                        </div>
                      </div>
                    </div>
    
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={loading}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                      >
                        <span>Submit</span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      );
}