import React, { useContext, useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import EstateCard from "./EstateCard";
import RealProperty from "./_partials/RealEstate";
import CashOnhand from "./_partials/CashHand";
import BankAccount from "./_partials/BankAccount";
import Brokerage from "./_partials/Brokerage";
import Stocks from "./_partials/Stocks";
import Bonuses from "./_partials/Bonuses";
import Business from "./_partials/BusinessInterest";
import Retirement from "./_partials/RetirementBenefits";
import Insurance from "./_partials/InsuranceAnnuities";
import Vehicle from "./_partials/MotorVehicles";
import MoneyOwed from "./_partials/MoneyOwed";
import PersonalProperty from "./_partials/PersonalProperty";
import Livestock from "./_partials/Livestock";
import ClubMemberships from "./_partials/ClubMemberships";
import TravelAwardBenefits from "./_partials/TravelAwardBenefits";
import Miscellaneous from "./_partials/Miscellaneous";
import CommunityLiabilities from "./_partials/CommunityLiabilities";
import ChildAssets from "./_partials/ChildAssets";
import PropertyHeld from "./_partials/PropertyHeld";
import EquitableClaims from "./_partials/EquitableClaim";
import EstateCategories from "./_partials/EstateCategoriesModal/EstateCategories";
import { EncryptStorage } from "encrypt-storage";
import Link from "@material-ui/core/Link";
import Axios from "axios";
import {
  SnackBarOptionsContext,
  EstateContext,
} from "../../../hooks/ContextStore";

function Estate() {
  const [open, setOpen] = useState(false);
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const data = decypt_data;
  const client_case_id = data ? data?.case?.id : null;
  const [downloading, setDownloading] = useState(false);
  const [snackbarOptions, setSnackBarOptions] = useContext(
    SnackBarOptionsContext
  );
  const [category, setCategory] = useContext(EstateContext);
  const history = useHistory();

  const handleDownload = () => {
    setDownloading(true);

    setSnackBarOptions({
      show: true,
      title: "Archiving Large File...",
    });

    Axios.get(`/api/v2/files/estate/batch/download/${client_case_id}`)
      .then((res) => {
        setSnackBarOptions({
          show: false,
          title: "",
        });
        window.open(res.data, "_blank");
      })
      .catch((err) => {
        if(err.response.status === 404){
          setSnackBarOptions({
            show: true,
            title: "No Files to Download",
          });
        }else{
          setSnackBarOptions({
            show: true,
            title: "Unknown Error Occured",
          });
        }
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (category?.real_property === 1 || category?.mineral_interest === 1) {
      history.push("/estate/real-estate/");
    } else if (category?.cash === 1 || category?.equivalents === 1) {
      history.push("/estate/cash-onhand");
    } else if (category?.bank_account === 1) {
      history.push("/estate/bank-accounts");
    } else if (category?.brokerage === 1) {
      history.push("/estate/brokerage");
    } else if (
      category?.public_stocks === 1 ||
      category?.employee_stock_option === 1 ||
      category?.employee_stock === 1
    ) {
      history.push("/estate/stocks");
    } else if (category?.bonuses === 1) {
      history.push("/estate/bonuses");
    } else if (category?.business_interest === 1) {
      history.push("/estate/business-interests");
    } else if (
      category?.contribution_plan === 1 ||
      category?.benefit_plan === 1 ||
      category?.ira_sep === 1 ||
      category?.military_benefit === 1 ||
      category?.government_benefits === 1 ||
      category?.compensation_benefits === 1 ||
      category?.union_benefits === 1
    ) {
      history.push("/estate/retirement-benefits");
    } else if (category?.life_insurance === 1 || category?.annuities === 1) {
      history.push("/estate/insurance-annuities");
    } else if (category?.vehicles === 1) {
      history.push("/estate/vehicles");
    } else if (category?.money_owned === 1) {
      history.push("/estate/money-owed");
    } else if (
      category?.client_possession === 1 ||
      category?.spouse_possession === 1 ||
      category?.spouse_both === 1
    ) {
      history.push("/estate/personal-property");
    } else if (category?.pets === 1 || category?.livestock === 1) {
      history.push("/estate/livestock");
    } else if (category?.club_membership === 1) {
      history.push("/estate/club-memberships");
    } else if (category?.travel_award_benefit === 1) {
      history.push("/estate/travel");
    } else if (
      category?.intellectual_property === 1 ||
      category?.safe_deposit === 1 ||
      category?.storage_facilities === 1 ||
      category?.contingent_assets === 1 ||
      category?.other_assets === 1
    ) {
      history.push("/estate/miscellaneous-property");
    } else if (
      category?.credit_card === 1 ||
      category?.federal_liabilities === 1 ||
      category?.contingent_liabilities === 1 ||
      category?.amount_owned_to_attorney_or_professionals === 1 ||
      category?.other_liabilities === 1
    ) {
      history.push("/estate/community");
    } else if (category?.children_assets === 1) {
      history.push("/estate/child-accounts");
    } else if (
      category?.asset_held_for_either_party === 1 ||
      category?.asset_held_for_benefit === 1
    ) {
      history.push("/estate/property-trust");
    } else if (
      category?.equitable_for_community_estate === 1 ||
      category?.equitable_for_separate_estate === 1
    ) {
      history.push("/estate/equitable-claims");
    }
  }, [category]);

  return (
    <div id="estatePage" className="d-flex flex-column">
      <div className="col-12 p-6 bg-white border-0 d-flex mb-7 align-items-center shadow rounded">
        <div className="">
          <h3>Estate</h3>
          <h3 className="text-muted font-weight-bold font-size-sm">
            Inventory of marital estate
          </h3>
        </div>


        <div className="ml-auto d-flex">
          <button
            type="button"
            className="btn btn-primary ml-auto"
            onClick={handleClickOpen}
          >
            Categories
          </button>
          <EstateCategories
            handleClickOpen={handleClickOpen}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
          />
          <Link href="/estate-division">
            <button type="button" className="ml-3 btn custom-blue-button">
              Estate Division
            </button>
          </Link>
          <button
            type="button"
            className="ml-3 btn btn-primary d-none d-md-block"
            disabled={downloading}
            onClick={handleDownload}
          >
            Download Files{" "}
            {downloading ? (
              <span className="ml-3 spinner spinner-white p-3"></span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>

      <div className="d-flex flex-row flex-wrap">
        <EstateCard />
        <div className="estate-page-holder">
          <Switch>
            <Route path="/estate/real-estate" component={RealProperty} />
            <Route path="/estate/cash-onhand" component={CashOnhand} />
            <Route path="/estate/bank-accounts" component={BankAccount} />
            <Route path="/estate/brokerage" component={Brokerage} />
            <Route path="/estate/stocks" component={Stocks} />
            <Route path="/estate/bonuses" component={Bonuses} />
            <Route path="/estate/business-interests" component={Business} />
            <Route path="/estate/retirement-benefits" component={Retirement} />
            <Route path="/estate/insurance-annuities" component={Insurance} />
            <Route path="/estate/vehicles" component={Vehicle} />
            <Route path="/estate/money-owed" component={MoneyOwed} />
            <Route
              path="/estate/personal-property"
              component={PersonalProperty}
            />
            <Route path="/estate/livestock" component={Livestock} />
            <Route
              path="/estate/club-memberships"
              component={ClubMemberships}
            />
            <Route path="/estate/travel" component={TravelAwardBenefits} />
            <Route
              path="/estate/miscellaneous-property"
              component={Miscellaneous}
            />
            <Route path="/estate/community" component={CommunityLiabilities} />
            <Route path="/estate/child-accounts" component={ChildAssets} />
            <Route path="/estate/property-trust" component={PropertyHeld} />
            <Route
              path="/estate/equitable-claims"
              component={EquitableClaims}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Estate;
