import { EncryptStorage } from "encrypt-storage";
import React, { Suspense, lazy, useState, useRef, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Basics from "./pages/Basics/Basics";
import ClientOverview from "./pages/Basics/ClientOverview";
import Budget from "./pages/Budget/Budget";
import Children from "./pages/Children/Children";
// import{ ClientDashboard }from "./pages/ClientDashboard";
import ClientDashboard from "./pages/ClientDashboard";
import DocumentsCard from "./pages/Documents/DocumentsCard";
import Estate from "./pages/Estate/Estate";
import EstateDefault from "./pages/Estate/EstateDefault";
import EstateDivision from "./pages/Estate/EstateDivision";
import NotesPage from "./pages/Notes/NotesPage";
import Timeline from "./pages/Timeline/Timeline";
import Documents from "./pages/Documents/DocumentPage";
import { useSelector } from "react-redux";
import Axios from "axios";
import { GetMainRole, PageIsSubscribed } from "../Functions/General";
import NoSubscriptionClient from "./modules/UserProfile/Subscription/Client/NoSubscriptionClient";
import IdleComponent from "./pages/IdleComponent";
import { getClientCaseDetails } from "./modules/Auth/_redux/authCrud";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function ClientPage() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const { user } = useSelector((state) => state.auth);
  const role = user ? user.data.roles[0].name : null;
 
  useEffect(() => {
    encryptStorage.removeItem("client");
    // encryptStorage.removeItem("spouse");
    encryptStorage.removeItem("client_case_id");

    // Clean the spouse data by removing the client_case property

    // const cleanedSpouseData = {
    //   ...decypt_data.case.spouse,
    //   client_case: undefined,
    // };

    // Set the cleaned client and spouse data in encryptStorage
    encryptStorage.setItem("client", decypt_data.case.client);
    // encryptStorage.setItem("spouse", cleanedSpouseData);
    encryptStorage.setItem("client_case_id", decypt_data.case?.id);
  }, []);
  
  const getSizeOfLocalStorage = () => {
    const data = JSON.stringify(localStorage);
    const sizeInBytes = new Blob([data]).size;
    return sizeInBytes;
  };
  
  const localStorageSize = getSizeOfLocalStorage();
  console.log("LocalStorage size:", localStorageSize, "bytes");



  return (
    <>
      {/* {login_security ? (
        login_security.google2fa_enable == true &&
        login_security.google2fa_verified == false ? (
          <Redirect from="/auth" to="/auth/2FA" />
        ) : (
          <Redirect from="/auth" to="/" />
        )
      ) : (
        ""
      )} */}
      <IdleComponent />
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {PageIsSubscribed() ? (<Redirect from="/" to="/client-overview" exact/>) : (<ContentRoute path="/" component={NoSubscriptionClient} />)}
          <ContentRoute
            exact
            path="/"
            component={
              PageIsSubscribed()
                ? () => <ContentRoute path="/client-overview" component={ClientOverview} />
                : () => (
                    <ContentRoute path="/" component={NoSubscriptionClient} />
                  )
            }
          />
          
          {PageIsSubscribed() ? (
            <ContentRoute path="/client-overview" component={ClientOverview} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}

          {PageIsSubscribed() ? (
            <ContentRoute path="/timeline" component={Timeline} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/estates/default" component={EstateDefault} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/documents" component={Documents} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}

          {PageIsSubscribed() ? (
            <ContentRoute path="/notes" component={NotesPage} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute 
            path="/basics" 
            render={() => <Basics mode={'client'} />}
            />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/budget/" component={Budget} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <Route path="/user-profile" component={UserProfilepage} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          <ContentRoute path="/estate" component={Estate} />
          <ContentRoute path="/estate-division" component={EstateDivision} />
          <ContentRoute path="/children" component={Children} />
          <Redirect exact from="/auth/mfa" to={role === "client" ? "/" : "/attorney"} />  {/* remove this if things go wrong */}
          <Redirect exact from="/auth/login" to={role === "client" ? "/" : "/attorney"} />      {/* remove this if things go wrong */}
          <Redirect to="../error/error-v3" />
        </Switch>
      </Suspense>
    </>
  );
}
