import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CreateClientPossession from "./ClientPossessionModal/Create";
import EditClientPossession from "./ClientPossessionModal/Edit";
import DeleteClientPossession from "./ClientPossessionModal/Delete";
import CreateSpousePossession from "./SpousePossessionModal/Create";
import EditSpousePossession from "./SpousePossessionModal/Edit";
import DeleteSpousePossession from "./SpousePossessionModal/Delete";
import CreateBothPossession from "./BothPossessionModal/Create";
import EditBothPossession from "./BothPossessionModal/Edit";
import DeleteBothPossession from "./BothPossessionModal/Delete";
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import { EstateContext,ClientPropertyContext, SpousePropertyContext, BothPropertyContext } from "../../../../hooks/ContextStore";
import ShowNoteComponent from "./ShowNoteComponent";
import FileUpload from "../Components/FileUpload";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function createData(desc, asset, debt, client_sp, spouse_sp, net_val) {
  return {
    desc,
    asset,
    debt,
    client_sp,
    spouse_sp,
    net_val,
    details: [
      {
        addr: "Lorem Ipsum Address",
        county: "11091700",
        fair: 3,
        liens: 4,
        property: 5,
        notes: "Lorem Ipsum",
      },
    ],
  };
}

function BothPossessionRow(props) {
  const { row, client_case_id, setbothProperties } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.description}
        </TableCell>
        {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value}
          </TableCell>
        ) : null}
        {/* {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.debt}
          </TableCell>
        ) : null} */}
        {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp}
          </TableCell>
        ) : null}
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditBothPossession
                row={row}
                client_case_id={client_case_id}
                setbothProperties={setbothProperties}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteBothPossession setbothProperties={setbothProperties} row={row} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'both-possession'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Description of Asset(s) :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.description}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function BothPossession() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [bothproperties, setbothProperties] = useContext(BothPropertyContext);





  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>In Possession of Both</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              You can either estimate the total value of all items, or list the
              items out individually. When in doubt, as your attorney for their
              preference.
            </span>
          </div>
          <div className="ml-auto">
            <CreateBothPossession
              client_case_id={client_case_id}
              setbothProperties={setbothProperties}
              type={"3"}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bothproperties.map((row) => (
                <BothPossessionRow
                  key={row.id}
                  row={row}
                  setbothProperties={setbothProperties}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function SpousePossessionRow(props) {
  const { row, client_case_id,setspouseProperties } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.description ?? "N/A"}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditSpousePossession
                row={row}
                client_case_id={client_case_id}
                setspouseProperties={setspouseProperties}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteSpousePossession setspouseProperties={setspouseProperties} row={row} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'spouse-possession'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Description of Asset(s) :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.description}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SpousePossession() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [spouseproperties, setspouseProperties] = useContext(SpousePropertyContext);


  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>In Possession of Spouse</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              You can either estimate the total value of all items, or list the
              items out individually. When in doubt, as your attorney for their
              preference.
            </span>
          </div>
          <div className="ml-auto">
            <CreateSpousePossession
              client_case_id={client_case_id}
              setspouseProperties={setspouseProperties}
              type={"2"}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {spouseproperties.map((row) => (
                <SpousePossessionRow
                  key={row.id}
                  row={row}
                  setspouseProperties={setspouseProperties}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function ClientPossessionRow(props) {
  const { row, client_case_id,setclientProperties } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.description ?? "N/A"}
        </TableCell>       
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditClientPossession         
                row={row}
                client_case_id={client_case_id}
                setclientProperties={setclientProperties}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteClientPossession setclientProperties={setclientProperties}row={row} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'client-possession'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Description of Asset(s) :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.description}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ClientPossession() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [clientproperties, setclientProperties] = useContext(ClientPropertyContext);

 

  

  return (
    <>
      <div className="card-title mb-0">
        <div className="d-flex px-9 align-items-center">
          <div className="d-flex flex-column">
            <h4>In Possession of Client</h4>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              You can either estimate the total value of all items, or list the
              items out individually. When in doubt, as your attorney for their
              preference.
            </span>
          </div>
          <div className="ml-auto">
            <CreateClientPossession
              client_case_id={client_case_id}
              setclientProperties={setclientProperties}
              type={"1"}
            />
          </div>
        </div>
      </div>
      <div className="pb-10 pl-10 pr-10 pt-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                  Description
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Value
                </TableCell>
                {/* <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Debt
                </TableCell> */}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Client SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Spouse SP
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Net Value
                </TableCell>
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientproperties.map((row) => (
                <ClientPossessionRow
                  key={row.id}
                  row={row}
                  setclientProperties={setclientProperties}
                  client_case_id={client_case_id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

function PersonalProperty() {
  const [category, setCategory] = useContext(EstateContext);
  const data = category != undefined ? category : null;
  const ctgry = data ? true : false;
  let client, spouse, both;

  if (ctgry) {
    if (Boolean(data.client_possession) === true) {
      client = <ClientPossession />;
    }
    if (Boolean(data.spouse_possession) === true) {
      spouse = <SpousePossession />;
    }
    if (Boolean(data.spouse_both) === true) {
      both = <BothPossession />;
    }
  }

  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        <div className="card-header border-0 mt-3">
          <h3 class="card-title align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Personal Property
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Household items, furniture, appliances, electronics, sporting
              goods, jewelry, artwork, etc.
            </span>
          </h3>
        </div>
        <hr />
        {client}
        {spouse}
        {both}
      </div>
    </>
  );
}

export default PersonalProperty;
