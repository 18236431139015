import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom"; 
import ChildrenCard from './ChildrenCard'
import CustodyOverview from './_partials/CustodyOverview';
import FormChildren from "./_partials/FormChildren";
import ParentingAndCare from './_partials/ParentingAndCare';
import PriorOrders from './_partials/PriorOrders';
import Schools from './_partials/Schools';
import Witnesses from './_partials/Witnesses';
import Activities from './_partials/Activities';
import LivingEnvironments from './_partials/LivingEnvironments'; 
import WorkSchedule from './_partials/WorkSchedule'; 
import SpecialNeeds from './_partials/SpecialNeeds';
import DifficultIssues from './_modal/DifficultIssues';
import OtherRelevantIssues from './_partials/OtherRelevantIssues';
import OtherHouseHold from './_partials/OtherHouseHold';
import ChildrenCourtOrder from '../Basics/_partials/ChildrenCourtOrder';

function Children() {
    return (
        <div id="childrenPage" className="d-flex flex-row flex-wrap">
            <div className="col-12 p-6 bg-white border-0 d-flex mb-7 align-items-center rounded">
                <div className="timeline">
                    <h3>Children</h3>
                    <h3 className="text-muted font-weight-bold font-size-sm">Information About Children and Parenting</h3>
                </div>
                <div className="ml-auto"> 
                </div>
            </div>
            <ChildrenCard/>
            <div className="children-content-holder ml-lg-4">
        
                <Switch>
                    <Route path="/children/children" component={FormChildren}/>
                </Switch>
                <Switch>
                    <Route path="/children/prior-orders" component={PriorOrders}/>
                </Switch> 
                <Switch>
                    <Route path="/children/custody-overview" component={CustodyOverview}/>
                </Switch>
                <Switch>
                    <Route path="/children/witnesses" component={Witnesses}/>
                </Switch>
                <Switch>
                    <Route path="/children/schools" component={Schools}/>
                </Switch>
                <Switch>
                    <Route path="/children/parenting-care" component={ParentingAndCare}/>
                </Switch>
                <Switch>
                    <Route path="/children/activities" component={Activities}/>
                </Switch>  
                <Switch>
                    <Route path="/children/living-environment" component={LivingEnvironments}/>
                </Switch> 
                <Switch>
                    <Route path="/children/work-schedule" component={WorkSchedule}/>
                </Switch> 
                <Switch>
                    <Route path="/children/special-needs" component={SpecialNeeds}/>
                </Switch>  
                <Switch>
                    <Route path="/children/difficult-issues" component={DifficultIssues}/>
                </Switch> 
                <Switch>
                    <Route path="/children/other-issues" component={OtherRelevantIssues}/>
                </Switch> 
                <Switch>
                    <Route path="/children/household" component={OtherHouseHold}/>
                </Switch> 
                <Switch>
                    <Route path="/children/court-order" component={ChildrenCourtOrder}/>
                </Switch>
            </div>
        </div>
    )
}

export default Children