import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CreateVehicle from "./MotorVehicleModal/Create";
import EditVehicle from "./MotorVehicleModal/Edit";
import DeleteVehicle from "./MotorVehicleModal/Delete";
import { EncryptStorage } from "encrypt-storage";
import Axios from "axios";
import moment from "moment";
import ShowNoteComponent from "./ShowNoteComponent";
import FileUpload from "../Components/FileUpload";
import Loading from "../../../pages/Loading"
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function MotorVehicleRow(props) {
  const { row, client_case_id, setVehicles } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ color: blue }} />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="font-weight-bold fs-1">
          {row.year} {row.make ?? "N/A"} {row.model}
        </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.value ?? ""} as of ({moment(row.value_date).format("L")})
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.balance_loan ?? ""}
          </TableCell>
          <TableCell align="left" className="font-weight-bold fs-1">
            ${row.client_sp ?? ""}
          </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.spouse_sp ?? ""}
        </TableCell>
        <TableCell align="left" className="font-weight-bold fs-1">
          ${row.net_value ?? ""}
        </TableCell>
        <TableCell align="left">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <EditVehicle
                client_case_id={client_case_id}
                row={row}
                setVehicles={setVehicles}
              />
            </span>
          </div>
          <ShowNoteComponent row={row} />
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <DeleteVehicle row={row} setVehicles={setVehicles} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <FileUpload row={row} client_case_id={client_case_id} source={'motor-vehicles'}/>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Year :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.year}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Make :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.make}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Model :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.model}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Name of title :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.name}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      In possession of :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.possession}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      VIN # :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.number}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.value} as of ({moment(row.value_date).format("L")})
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Balance of loans :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.balance_loan}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Client SP:
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.client_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Spouse SP :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.spouse_sp}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText className="font-weight-bold fs-1">
                      Net Value :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {row.net_value}
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function MotorVehicle() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const client_case_id = decypt_data ? decypt_data.case.id : null;
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false); 
  const fetch_data = () => {
    setLoading(true);
    Axios.get("api/estate/retirement/benefits/vehicle/" + client_case_id)
      .then((res) => {
        setVehicles(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);

  return (
    <>
      <div id="real-property" className={`card h-100 card-custom `}>
        <div className="card-header border-0 mt-3">
          <h3 class="card-title align-items-start flex-column align-self-center">
            <span class="card-label font-weight-bolder text-dark">
              Motor Vehicles, Airplanes, Cycles, Etc.
            </span>
            <span class="text-muted mt-0 font-weight-bold font-size-sm">
              Cars, Watercraft, Aircraft, Cycles, Recreational Vehicles, etc.
            </span>
          </h3>
        </div>
        <hr />
        <div className="card-title mb-0">
          <div className="d-flex px-9 align-items-center">
            <h4>Motor Vehicles, Airplanes, Cycles, etc.</h4>
            <div className="ml-auto">
              <CreateVehicle
                setVehicles={setVehicles}
                client_case_id={client_case_id}
              />
            </div>
          </div>
        </div>
        <div className="pb-10 pl-10 pr-10 pt-0">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                    Description
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Debt
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Client SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Spouse SP
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Net Value
                  </TableCell>
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.map((row) => (
                  <MotorVehicleRow
                    key={row.id}
                    row={row}
                    client_case_id={client_case_id}
                    setVehicles={setVehicles}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {loading && <Loading/> }
      </div>
    </>
  );
}
export default MotorVehicle;
