import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import CreateNote from "./CreateNote";
import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import TextTruncate from "react-text-truncate"; // recommend
import ShowNote from "./ShowNote";
import moment from "moment";
import DeleteNote from "./DeleteNote";
import EditNote from "./EditNote";
import FileUpload from "./FileUpload";
import { useSelector } from "react-redux";
import { GetUserId } from "../../../Functions/General";
import { Skeleton } from "@material-ui/lab";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function RecentActivitiesRow(props) {
  const { row, client_case_id, setNotes } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const parsedExcerpt = new DOMParser().parseFromString(
    row.description,
    "text/html"
  );
  const excerptText = parsedExcerpt.body.innerText.trim();

  useEffect(() => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
        setOpen(false);
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  }, []);
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {isMobile ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowDownIcon style={{ color: blue }} />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>
        ) : null}
        {!isMobile ? (
          <TableCell
            component="th"
            scope="row"
            className="font-weight-bold fs-1"
          >
            {row.title}
          </TableCell>
        ) : null}
        <TableCell align="left" className="font-weight-bold fs-1">
          {excerptText.length > 25
            ? excerptText.substring(0, 25) + "..."
            : excerptText}
        </TableCell>
        {!isMobile ? (
          <TableCell align="left" className="font-weight-bold fs-1">
            {moment(row.created_at).format("YYYY-MM-DD")}
          </TableCell>
        ) : null}
        <TableCell align="left" className="font-weight-bold fs-1">
          {row.user ? `${row.user.firstname} ${row.user.lastname}` : ""}
        </TableCell>
        <TableCell align="left" className="d-flex">
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1 mr-1">
            <ShowNote row={row} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1 mr-1">
            <EditNote
              row={row}
              setNotes={setNotes}
              client_case_id={client_case_id}
            />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1">
            <DeleteNote row={row} setNotes={setNotes} />
          </div>
          <div className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1">
            <FileUpload
              row={row}
              setNotes={setNotes}
              client_case_id={client_case_id}
            />
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className="shadow mb-3">
                <List>
                  <ListItem>
                    <ListItemText className="font-weight-bold fs-1">
                      Date :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      {moment(row.created_at).format("YYYY-MM-DD")}
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText className="font-weight-bold fs-1">
                      Description :
                    </ListItemText>
                    <ListItemSecondaryAction className="font-weight-bold fs-1">
                      <div
                        dangerouslySetInnerHTML={{ __html: row.description }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function NotesPage() {
  const [open, setOpen] = useState(false);
  const mobile = window.matchMedia("(max-width: 768px)");
  const [isMobile, setIsMobile] = useState(mobile.matches);
  const encryptStorage = new EncryptStorage("secret-key");
  const client_case_id = encryptStorage.getItem("client_case_id");
  const [notes, setNotes] = useState([]);
  const [name, setName] = useState("");
  const { user } = useSelector((state) => state.auth);
  const role = user ? user.data.roles[0].name : null;
  const user_id = GetUserId();

  /**
   * Added Loading State for the page while fetching and filtering notes.
   * Para dli boring ba. Hahahahaha 
   */
  const [isFetching, setIsFetching] = useState(false);

  const mobileHandler = () => {
    const windowHandler = () => {
      setIsMobile(mobile.matches);
      if (!isMobile) {
      }
    };

    mobile.addEventListener("change", windowHandler);
    return () => {
      mobile.removeEventListener("change", windowHandler);
    };
  };

  const fetch_interest = () => {
    setIsFetching(true);
    Axios.get("api/note/index/" + client_case_id)
      .then((res) => {
        if (role === "client") {
          const filtered = res.data.data.filter((note) => {
            return note.created_by === user_id;
          });
          setNotes(filtered);
          setIsFetching(false);
        } else {
          setNotes(res.data.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(()=>{
        setIsFetching(false);
      });
  };

  useEffect(() => {
    mobileHandler();
    fetch_interest();
  }, []);


  /** 
   * Check notes if role is client filter only the notes created by the client.
  */
  const clientOnlyNotes = useMemo(() => {
      if(role === 'client'){
        return notes.filter((note) => {
          return note.created_by === user_id;
        });
      }else{
        return notes;
      }
  }, [notes]);

  /**
   * Handle Search of notes.
   */
  const filteredNotes = useMemo(() => {
    return clientOnlyNotes.filter(
      (note) =>
        String(note.title)
          .toLowerCase()
          .includes(String(name).toLowerCase()) ||
        String(note.description)
          .toLowerCase()
          .includes(String(name).toLowerCase())
    );
  }, [name, clientOnlyNotes]);

  return (
    <>
      <div className="col-12 p-8 bg-white border-0 d-flex mb-7 align-items-center rounded">
        <div className="timeline">
          <h3 className="pt-2">Notes</h3>
        </div>
        <div className="ml-auto d-flex justify-content-center">
          <div className="px-4">
            <div className="input-icon">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                size="10"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <span>
                <i className="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <CreateNote client_case_id={client_case_id} setNotes={setNotes} />
        </div>
      </div>

      <div className="mt-5 col-md-12 col-xxl-12 p-0">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {isMobile ? <TableCell></TableCell> : null}
                {isMobile ? null : (
                  <TableCell className="font-weight-bold text-uppercase text-muted fs-1">
                    TITLE
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  DESCRIPTION
                </TableCell>
                {isMobile ? null : (
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    DATE
                  </TableCell>
                )}
                {isMobile ? null : (
                  <TableCell
                    align="left"
                    className="font-weight-bold text-uppercase text-muted fs-1"
                  >
                    CREATED BY
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  className="font-weight-bold text-uppercase text-muted fs-1"
                >
                  ACTIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching && Array(5).fill().map(() => (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="h1>"><Skeleton /></Typography>
                    </TableCell>
                  </TableRow>
              ))}
              {filteredNotes.map((row) => (
                <RecentActivitiesRow
                  key={row.id}
                  row={row}
                  client_case_id={client_case_id}
                  setNotes={setNotes}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default NotesPage;
