import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {blue} from '@material-ui/core/colors';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
});

function AssetHeldAnotherRow(props){
    const { row }                          = props;
    const [open, setOpen]                  = useState(false);
    const classes                          = useRowStyles();
    const mobile                           = window.matchMedia("(max-width: 768px)");
    const [isMobile, setIsMobile]          = useState(mobile.matches); 
  
    useEffect(() => {
        const windowHandler =()=> {
            setIsMobile(mobile.matches)
            if(!isMobile){
                setOpen(false)
            }
        };
        
        mobile.addEventListener("change", windowHandler);
        return () => {
            mobile.removeEventListener("change", windowHandler);
        }
    
    }, []);
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          {isMobile ? 
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon style={{color: blue}}/> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell> 
          : null }
          <TableCell component="th" scope="row" >
            {row.desc}
          </TableCell>
          {!isMobile ? <TableCell align="left" >${row.asset}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.debt}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.client_sp}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.spouse_sp}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.net_val}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.spouse_sp}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.net_val}</TableCell> : null}
          {!isMobile ? <TableCell align="left" >${row.net_val}</TableCell> : null}
          <TableCell align="left" className="d-flex">
                <div className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1 mr-1 h-0 w-0">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                    </span>
                    /
                </div>
                <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 h-0 w-0">
                    <i class="fas fa-sticky-note"></i>
                </div>
            </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <div className="shadow mb-3">
                  <List>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          Value :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $500
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          DEBT :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $5
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          CLIENT SP :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          SPOUSE SP :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          NET VALUE :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          TO CLIENT :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          TO SPOUSE :
                          </ListItemText>
                          <ListItemSecondaryAction >
                          $50
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                          <ListItemText >
                          % :
                          </ListItemText>
                          <ListItemSecondaryAction > 
                          0
                          </ListItemSecondaryAction>
                      </ListItem>
                  </List>
                  </div>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

export default AssetHeldAnotherRow